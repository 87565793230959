import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid2/models/grid-component-cell.interface';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';

@Component({
  selector: 'wp-skill-estimation-cell',
  templateUrl: './skill-estimation-cell.component.html',
  styleUrls: ['./skill-estimation-cell.component.scss'],
})
export class SkillEstimationCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: UntypedFormGroup;

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
}
