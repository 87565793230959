import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { NamingLocalizationComponent } from './naming-localization.component';
import { NamingLocalizationModalComponent } from './naming-localization-modal/naming-localization-modal.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [NamingLocalizationComponent, NamingLocalizationModalComponent],
  imports: [CommonModule, SharedModule, Grid2Module],
  exports: [NamingLocalizationComponent],
})
export class NamingLocalizationModule {}
