import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import { asyncScheduler, filter, take, throttleTime } from 'rxjs';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { TasksGridActionsService } from 'src/app/projects/card/project-tasks/core/tasks-grid-actions.service';
import { ProjectTaskTimelineService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/project-task-timeline.service';
import { TimelinePositionService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/timeline-position.service';
import { TimelineRenderingService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/timeline-rendering.service';
import { AbstractRightSideComponent } from 'src/app/shared-features/grid2/abstract-right-side-component.directive';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';

@Component({
  selector: 'tmt-timeline-right-side',
  templateUrl: './timeline-right-side.component.html',
  styleUrl: './timeline-right-side.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectTaskTimelineService,
    TimelineRenderingService,
    TimelinePositionService,
  ],
})
export class TimelineRightSideComponent
  extends AbstractRightSideComponent
  implements AfterViewInit, OnDestroy
{
  //** Canvas includes dynamic draws. */
  @ViewChild('dynamicCanvas') dynamicCanvas: ElementRef;
  //** Canvas includes all of visible dependencies. */
  @ViewChild('dependenciesCanvas') dependenciesCanvas: ElementRef;
  //** Canvas includes all of critical path dependencies. */
  @ViewChild('criticalPathCanvas') criticalPathCanvas: ElementRef;

  public get taskFormArrayGroups(): UntypedFormGroup[] {
    return this.taskDataService.formArray.controls as UntypedFormGroup[];
  }

  // Audit time for events in milliseconds.
  private auditTime = 15;
  private throttle = throttleTime(this.auditTime, asyncScheduler, {
    leading: true,
    trailing: true,
  });

  constructor(
    container: ElementRef,
    gridService: GridService,
    freezeTableService: FreezeTableService,
    renderer: Renderer2,
    public taskDataService: ProjectTasksDataService,
    public tasksGridActionsService: TasksGridActionsService,
    public timelineRenderingService: TimelineRenderingService,
    public service: ProjectTaskTimelineService,
    private cdr: ChangeDetectorRef,
    private dependenciesService: ProjectTaskDependenciesService,
    private projectCardService: ProjectCardService,
  ) {
    super(container, gridService, freezeTableService, renderer);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.dependenciesService.setDefaultAllowedMarkerTaskIds();
    /** Check changes subscription. */
    this.taskDataService.update$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dependenciesService.setDefaultAllowedMarkerTaskIds();
        this.cdr.detectChanges();
        this.service.updateTasks();

        // Drop selection if current selected task no more existing (after undo occasion)
        if (
          this.taskDataService.tasks.every(
            (task) => task.id !== this.gridService.selectedGroupValue?.id,
          )
        ) {
          this.gridService.selectGroup(null);
        }
      });

    /** Set default timeline by reload btn. */
    this.projectCardService.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.service.reloadTimeline();
      });

    this.service.init();
    if (this.taskDataService.tasks?.length) {
      this.service.initTimeline();
    }

    this.cdr.detectChanges();

    this.gridService.loading$
      .pipe(
        filter((x) => !x),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (!this.service.slots.length && this.taskDataService.tasks?.length) {
          this.service.initTimeline();
        }
        this.service.initScrollPosition();
      });

    this.freezeTableService.currentScrollPosition$
      .pipe(
        this.throttle,
        filter(() => !!this.service.slots.length),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((currentScrollPosition: number | null) => {
        this.projectCardService.timelineScrollPosition = currentScrollPosition;
      });
  }

  public ngAfterViewInit(): void {
    this.timelineRenderingService.dynamicCanvas =
      this.dynamicCanvas.nativeElement;
    this.timelineRenderingService.dependenciesCanvas =
      this.dependenciesCanvas.nativeElement;
    this.timelineRenderingService.criticalPathCanvas =
      this.criticalPathCanvas.nativeElement;

    this.timelineRenderingService.drawDependencies();
    this.service.updateTasks$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.detectChanges();
        this.timelineRenderingService.drawDependencies();
        this.timelineRenderingService.highlightTaskDependencies();
        if (this.taskDataService.isShowCriticalPath$.getValue()) {
          this.timelineRenderingService.drawCriticalPathDependencies();
        }
      });

    this.taskDataService.criticalDependencies$
      .pipe(
        filter(() => this.taskDataService.isShowCriticalPath$.getValue()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
        this.timelineRenderingService.drawCriticalPathDependencies();
      });

    this.taskDataService.isShowCriticalPath$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.cdr.detectChanges();
        if (value) {
          this.timelineRenderingService.drawCriticalPathDependencies();
        } else {
          this.timelineRenderingService.clearCriticalPathCanvas();
        }
      });

    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.timelineRenderingService.highlightTaskDependencies();
      });

    this.taskDataService.isShowMainTask$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isShow) => {
        if (
          !isShow &&
          this.gridService.selectedGroup$.getValue() &&
          !this.gridService.selectedGroupValue?.leadTaskId
        ) {
          this.gridService.selectGroup(null);
          this.cdr.detectChanges();
        }
      });
  }

  public ngOnDestroy(): void {
    this.service.dispose();
  }
}
