import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { ContactInteractionService } from 'src/app/contacts/card/contact-interaction/contact-interaction.service';
import { Interaction } from 'src/app/contacts/model/interaction.model';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';

@Component({
  selector: 'tmt-contact-interaction-form',
  templateUrl: './contact-interaction-form.component.html',
  styleUrl: './contact-interaction-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInteractionFormComponent implements OnChanges {
  @Input() public interaction: Interaction;

  @Output() onCancel = new EventEmitter<void>();

  public isSaving = signal<boolean>(false);
  public interactionForm = this.fb.group({
    actualDate: null,
    plannedDate: [null, [Validators.required]],
    type: [null, [Validators.required]],
    performer: [null, [Validators.required]],
    lead: null,
    organization: null,
    interactionContacts: [[]],
    description: null,
  });

  private userInitialValue = {
    id: this.appService.session.user.id,
    name: this.appService.session.user.name,
  };

  constructor(
    public readonly contactInteractionService: ContactInteractionService,
    public readonly appService: AppService,
    private readonly notificationService: NotificationService,
    private readonly fb: UntypedFormBuilder,
    @Optional() private readonly contactCardService: ContactCardService,
  ) {
    this.contactInteractionService.interactionTypes$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.initForm();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['interaction']) {
      this.interactionForm.patchValue(this.interaction, { emitEvent: false });

      if (this.interaction.interactionContacts?.length) {
        this.interactionForm.get('interactionContacts').patchValue(
          this.interaction.interactionContacts.map((interactionContact) => ({
            id: interactionContact.contact.id,
            name: interactionContact.contact.name,
          })),
          {
            emitEvent: false,
          },
        );
      }
    }
  }

  /** Clears and cancel form. */
  public cancel(): void {
    this.onCancel.emit();
    this.resetForm();
    this.interaction = null;
  }

  /** Saves interaction. */
  public save(): void {
    this.interactionForm.markAllAsTouched();
    if (!this.interactionForm.valid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    if (!this.interaction?.id) {
      this.contactInteractionService
        .saveInteraction(this.interactionForm.value)
        .subscribe((isSaved) => {
          this.isSaving.set(false);
          if (isSaved) {
            this.resetForm();
            this.initForm();
          }
        });
    } else {
      this.contactInteractionService
        .editInteraction(this.interactionForm.value, this.interaction.id)
        .subscribe((isEdited) => {
          this.isSaving.set(false);
          if (isEdited) {
            this.cancel();
          }
        });
    }
  }

  /** Resets form. */
  private resetForm(): void {
    this.interactionForm.markAsPristine();
    this.interactionForm.markAsUntouched();
    this.interactionForm.reset();
  }

  /** Initializes form with default values. */
  private initForm(): void {
    this.interactionForm
      .get('type')
      .setValue(this.contactInteractionService.defaultInteractionType, {
        emitEvent: false,
      });
    this.interactionForm.get('performer').setValue(this.userInitialValue, {
      emitEvent: false,
    });
    if (this.contactCardService) {
      this.interactionForm.get('interactionContacts').setValue(
        [
          {
            id: this.contactCardService.contactId,
            name: this.contactCardService.contact.name,
          },
        ],
        {
          emitEvent: false,
        },
      );
    }
  }
}
