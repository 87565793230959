import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { LifecycleListComponent } from './list/lifecycle-list.component';
import { LIFECYCLE_LIST } from 'src/app/shared/lists/lifecycle.list';
import { LifecycleCardComponent } from './card/lifecycle-card.component';
import { LifecycleToolbarComponent } from './card/lifecycle-toolbar/lifecycle-toolbar.component';
import { LifecycleCardService } from './card/lifecycle-card.service';
import { StateModalComponent } from './card/state-modal/state-modal.component';
import { TransitionsCellComponent } from './card/transitions-cell/transitions-cell.component';
import { TransitionsService } from './card/transitions.service';
import { NamingLocalizationModule } from '../shared/naming-localization/naming-localization.module';
import { PerformersModule } from '../shared/performers/performers.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { TransitionsToolbarComponent } from 'src/app/settings-app/lifecycle/card/state-modal/transitions-toolbar/transitions-toolbar.component';
import { TransitionModalComponent } from 'src/app/settings-app/lifecycle/card/state-modal/transition-modal/transition-modal.component';
import { TransitionFormComponent } from 'src/app/settings-app/lifecycle/card/state-modal/transition-modal/transition-form/transition-form.component';
import { TransitionFormService } from 'src/app/settings-app/lifecycle/card/state-modal/transition-modal/transition-form/transition-form.service';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';
import { KindSettingsComponent } from 'src/app/settings-app/lifecycle/card/kind-settings/kind-settings.component';
import { KindStateModalComponent } from 'src/app/settings-app/lifecycle/card/kind-settings/kind-state-modal/kind-state-modal.component';
import { KindToolbarComponent } from 'src/app/settings-app/lifecycle/card/kind-settings/kind-toolbar/kind-toolbar.component';

@NgModule({
  declarations: [
    LifecycleListComponent,
    LifecycleCardComponent,
    LifecycleToolbarComponent,
    StateModalComponent,
    TransitionsToolbarComponent,
    TransitionModalComponent,
    TransitionsCellComponent,
    TransitionFormComponent,
    KindSettingsComponent,
    KindStateModalComponent,
    KindToolbarComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerformersModule,
    NamingLocalizationModule,
    Grid2Module,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.lifecycles',
          url: '/lifecycles',
          component: LifecycleListComponent,
          params: {
            navigation: 'settings.lifecycles',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: LIFECYCLE_LIST },
          ],
        },
        {
          name: 'settings.lifecycle',
          redirectTo: 'settings.lifecycle.schema',
          params: {
            navigation: 'settings.lifecycles',
          },
          url: '/lifecycles/{entityId:guid}',
          component: LifecycleCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
  ],
  providers: [LifecycleCardService, TransitionsService, TransitionFormService],
  exports: [TransitionFormComponent],
})
export class LifecyclesModule {}
