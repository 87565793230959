import { Injectable } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/angular';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { share, switchMap, tap } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  WorkflowAction,
  WorkflowFunctionBase,
  WorkflowFunctionNotification,
  WorkflowFunctionTask,
  WorkflowPerformer,
  WorkflowSchema,
  WorkflowTransitionCondition,
} from 'src/app/settings-app/workflows/model/workflow-function.model';
import { WorkflowDeclaration } from '../model/workflow-declaration.interface';
import { WorkflowActionModalComponent } from './workflow-function/workflow-action-modal/workflow-action-modal.component';
import { WorkflowFunctionComponent } from './workflow-function/workflow-function.component';
import { WorkflowTransitionModalComponent } from './workflow-function/workflow-transition-modal/workflow-transition-modal.component';
import { PermissionSet } from 'src/app/shared/models/entities/settings/permission-set.model';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { Group } from 'src/app/shared/models/entities/settings/group.model';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import _ from 'lodash';
import { WorkflowConditionComponent } from './workflow-condition/workflow-condition.component';
import { WorkflowConditionModalComponent } from './workflow-condition/workflow-condition-modal/workflow-conditon-modal.component';
import { WorkflowFunctionType } from '../model/workflow.enum';
import { WorkflowNotificationComponent } from './workflow-notification/workflow-notification.component';

@Injectable()
export class WorkflowCardService {
  public workflowDeclaration: WorkflowDeclaration;

  constructor(
    private translate: TranslateService,
    private data: DataService,
    private modal: NgbModal,
    public app: AppService,
    public state: StateService,
    private navigationService: NavigationService,
    private message: MessageService,
    private fb: UntypedFormBuilder,
  ) {}

  private addActionSubject = new Subject<any | void>();
  public addAction$ = this.addActionSubject.asObservable();

  private addTransitionSubject = new Subject<UntypedFormGroup>();
  public addTransition$ = this.addTransitionSubject.asObservable();

  private functionSubject = new Subject<void>();
  public reloadFunction$ = this.functionSubject.asObservable();

  private updateSubject = new Subject<void>();
  public update$ = this.updateSubject.asObservable();

  public workflow: WorkflowSchema;

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  public getWorkflowDeclaration(entityId: string) {
    const query = {
      expand: {
        lifecycle: { select: ['id', 'name', 'entityType'] },
      },
    };
    return this.data
      .collection('WorkflowDeclarations')
      .entity(entityId)
      .get(query)
      .pipe(
        tap((workflowDeclaration: WorkflowDeclaration) => {
          this.workflowDeclaration = workflowDeclaration;
        }),
      );
  }

  /**
   * Gets a form for a new performer
   *
   * @returns Performers form group.
   * */
  public getPerformerFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: Guid.generate(),
      performer: { id: null, name: null, type: null },
    });
  }

  /**
   * Gets name of the role.
   *
   * @param role Role.
   * @returns Role name.
   * */
  public getRoleName(role: string): string {
    return this.translate.instant(`shared2.props.role${role}`);
  }

  public setWorkflow(workflow: WorkflowSchema) {
    this.workflow = workflow;
  }

  /** Получение схемы воркфлоу. */
  public getWorkflowSchema() {
    return this.data
      .collection('WorkflowDeclarations')
      .entity(this.workflowDeclaration.id)
      .function(`GetWorkflowSchema`)
      .get()
      .pipe(
        switchMap((workflowFunctions: WorkflowSchema) => {
          /* Готовим запросы, чтобы получить имена performers каждой функции */
          const userNameRequests = {};
          workflowFunctions.functions.map((workflowFunction) => {
            const performerOrRecipient =
              (workflowFunction as WorkflowFunctionTask)?.performers ??
              (workflowFunction as WorkflowFunctionNotification)?.recipients;
            performerOrRecipient?.map((p) => {
              if (p.userId) {
                userNameRequests[p?.userId] = this.getUser(p.userId);
              }
            });
          });

          const groupNameRequests = {};
          workflowFunctions.functions.map((workflowFunction) => {
            const performerOrRecipient =
              (workflowFunction as WorkflowFunctionTask)?.performers ??
              (workflowFunction as WorkflowFunctionNotification)?.recipients;
            performerOrRecipient?.map((p) => {
              if (p.groupId) {
                groupNameRequests[p.groupId] = this.getGroupName(p.groupId);
              }
            });
          });

          const permissionSetNameRequests = {};
          workflowFunctions.initiators?.map((initiator) => {
            if (initiator.userId) {
              userNameRequests[initiator?.userId] = this.getUser(
                initiator.userId,
              );
            }
            if (initiator.groupId) {
              groupNameRequests[initiator?.groupId] = this.getGroupName(
                initiator.groupId,
              );
            }

            if (initiator.permissionSetId) {
              permissionSetNameRequests[initiator?.permissionSetId] =
                this.getPermissionSetName(initiator?.permissionSetId);
            }
          });
          return forkJoin({
            workflowFunctions: of(workflowFunctions),
            ...userNameRequests,
            ...groupNameRequests,
            ...permissionSetNameRequests,
          });
        }),
        /* Записываем полученные имена users в соответствующий performer */
        switchMap((res) => {
          res.workflowFunctions?.functions.map((workflowFunction) => {
            const workflowFunctionTask =
              workflowFunction as WorkflowFunctionTask;
            /* Добавляем поля, необходимые для работы с исполнителями на клиенте */
            /** Для исполнителей */
            if (workflowFunctionTask.performers) {
              this.fillPerformersData(workflowFunctionTask.performers, res);
            }
            if (
              (workflowFunction as WorkflowFunctionNotification)?.recipients
            ) {
              this.fillRecipientsData(
                (workflowFunction as WorkflowFunctionNotification)?.recipients,
                res,
              );
            }
            workflowFunctionTask.actions?.map((workflowAction) => {
              workflowAction.actionForm?.requestedProperties?.map(
                (property: any) => {
                  const nameControl: NamedEntity = { id: null, name: null };
                  nameControl.id = property.name;
                  nameControl.name = this.app.getPropertyLocalizationName(
                    this.workflowDeclaration.lifecycle.entityType,
                    property.name,
                  );
                  property.nameControl = nameControl;
                },
              );
            });
          });
          this.fillPerformersData(res.workflowFunctions?.initiators, res);
          return of(res.workflowFunctions);
        }),
        tap((workflowSchema: WorkflowSchema) => {
          this.setWorkflow(workflowSchema);
        }),
      );
  }

  /**
   * Returns workflow action form property name localization.
   * @param lifecycleName Lifecycle name.
   * @param property Property name.
   */
  public getWorkflowActionFormPropertyName(
    lifecycleName: string,
    property: string,
  ): string {
    return this.translate.instant(
      `enums.transitionFormProperties.${lifecycleName}.${property}`,
    );
  }

  /**
   * Fills up the list of performers with data
   * */
  private fillPerformersData(
    performers: WorkflowPerformer[],
    usersData: object,
  ): void {
    performers?.map((performer) => {
      if (
        performer.userId &&
        performer.userId === usersData[performer.userId]?.id
      ) {
        performer.id = performer.userId;
        performer.name = usersData[performer.userId].name;
        performer.type = 'user';
      } else if (
        performer.groupId &&
        performer.groupId === usersData[performer.groupId]?.id
      ) {
        performer.id = performer.groupId;
        performer.name = usersData[performer.groupId].name;
        performer.type = 'group';
      } else if (performer.role) {
        performer.id = performer.role;
        performer.name = this.getRoleName(performer.role);
        performer.type = 'role';
      } else if (performer.permissionSetId) {
        performer.name = usersData[performer.permissionSetId].name;
        performer.id = performer.permissionSetId;
        performer.type = 'permissionSet';
      }
    });
  }

  private fillRecipientsData(
    recipients: WorkflowPerformer[],
    usersData: object,
  ): void {
    recipients?.map((recipient) => {
      if (
        recipient.userId &&
        recipient.userId === usersData[recipient.userId]?.id
      ) {
        recipient.id = recipient.userId;
        recipient.name = usersData[recipient.userId].name;
        recipient.type = 'user';
      } else if (
        recipient.groupId &&
        recipient.groupId === usersData[recipient.groupId]?.id
      ) {
        recipient.id = recipient.groupId;
        recipient.name = usersData[recipient.groupId].name;
        recipient.type = 'group';
      } else if (recipient.role) {
        recipient.id = recipient.role;
        recipient.name = this.getRoleName(recipient.role);
        recipient.type = 'role';
      } else if (recipient.permissionSetId) {
        recipient.name = usersData[recipient.permissionSetId].name;
        recipient.id = recipient.permissionSetId;
        recipient.type = 'permissionSet';
      }
    });
  }

  /**
   * Gets user.
   *
   * @param userId User ID.
   * @returns User observable.
   * */
  private getUser(userId: string): Observable<User> {
    return this.data
      .collection('Users')
      .entity(userId)
      .get({ select: ['id', 'name'] });
  }

  /**
   * Gets group.
   *
   * @param groupId Group ID.
   * @returns Group observable.
   * */
  private getGroupName(groupId: string): Observable<Group> {
    return this.data
      .collection('Groups')
      .entity(groupId)
      .get({ select: ['id', 'name'] });
  }

  /**
   * Gets permission set.
   *
   * @param permissionSetId Permission set ID.
   * @returns Permission set observable.
   * */
  private getPermissionSetName(
    permissionSetId: string,
  ): Observable<PermissionSet> {
    return this.data
      .collection('PermissionSets')
      .entity(permissionSetId)
      .get({ select: ['id', 'name'] });
  }

  public updateWorkflowSchema(workflow: WorkflowSchema) {
    return this.data
      .collection('WorkflowDeclarations')
      .entity(this.workflowDeclaration.id)
      .action('UpdateWorkflowSchema')
      .execute(workflow);
  }

  editWorkflow(stateId: string, lifecycleId: string): void {
    this.state.go('settings.workflowCard', {
      navigation: this.navigationService.selectedNavigationItem?.name,
      routeMode: RouteMode.continue,
      stateId,
      lifecycleId,
    });
  }

  private patchWorkflowFunction(newFunction: WorkflowFunctionBase) {
    this.workflow = {
      initialStateId: this.workflow.initialStateId,
      initiatorCanCancelInstance: this.workflow.initiatorCanCancelInstance,
      initiators: this.workflow.initiators,
      functions: this.workflow.functions.map((func) =>
        func.id === newFunction.id ? { ...newFunction } : func,
      ),
    };

    this.updateSubject.next();
  }

  private addWorkflowFunction(newFunction: WorkflowFunctionBase) {
    const functions = this.workflow.functions;
    newFunction.id = Guid.generate();
    functions.splice(functions.length - 1, 0, newFunction);
    this.workflow = {
      initialStateId: this.workflow.initialStateId,
      initiatorCanCancelInstance: this.workflow.initiatorCanCancelInstance,
      initiators: this.workflow.initiators,
      functions,
    };

    this.updateSubject.next();
  }

  /**
   * Edits workflow function.
   * @param workflowFunctionGroup Workflow function group.
   */
  public editWorkflowFunction(workflowFunctionGroup: UntypedFormGroup): void {
    const ref = this.modal.open(WorkflowFunctionComponent, {
      size: 'lg',
    });
    const instance = ref.componentInstance as WorkflowFunctionComponent;
    instance.workflowFunction = workflowFunctionGroup.value;
    instance.lifecycleId = this.workflowDeclaration.lifecycleId;

    ref.result.then(
      (newGroup) => {
        newGroup.performers = newGroup.performers.map((performerLine) => {
          const performer: any = _.pick(performerLine.performer, [
            'id',
            'name',
            'type',
          ]);

          if (performer.type === 'user') {
            performer.userId = performer.id;
          }

          if (performer.type === 'group') {
            performer.groupId = performer.id;
          }

          if (performer.type === 'role') {
            performer.role = performer.id;
          }

          return {
            ...performer,
            id: Guid.generate(),
          };
        });

        if (workflowFunctionGroup?.value.id) {
          workflowFunctionGroup.patchValue(newGroup);
          this.patchWorkflowFunction(newGroup);
        } else {
          this.addWorkflowFunction(newGroup);
        }
      },
      () => null,
    );
  }

  /**
   * Edits workflow condition.
   *
   * @param workflowFunctionGroup Workflow function group.
   */
  public editWorkflowCondition(workflowFunctionGroup: UntypedFormGroup): void {
    const functionFinish = this.workflow.functions.find(
      (f) => f.type === WorkflowFunctionType.finish,
    );
    const isElseCondition = {
      expression: 'true',
      isElse: true,
      moveToStateId: null,
      name: this.translate.instant('settings.workflows.card.function.else'),
      nextFunctionId: functionFinish?.id,
      id: null,
    };
    const ref = this.modal.open(WorkflowConditionComponent, {
      size: 'lg',
    });
    const instance = ref.componentInstance as WorkflowConditionComponent;
    instance.workflowFunction = workflowFunctionGroup.value;
    instance.lifecycleId = this.workflowDeclaration.lifecycleId;
    if (!workflowFunctionGroup.value.conditions) {
      instance.workflowFunction.conditions = [];
      instance.workflowFunction.conditions.push(isElseCondition);
    }
    ref.result.then(
      (newGroup) => {
        if (workflowFunctionGroup?.value.id) {
          workflowFunctionGroup.patchValue(newGroup);
          this.patchWorkflowFunction(newGroup);
        } else {
          this.addWorkflowFunction(newGroup);
        }
      },
      () => null,
    );
  }

  /**
   * Edits workflow notification.
   *
   * @param workflowFunctionGroup Workflow function group.
   */
  public editWorkflowNotification(
    workflowFunctionGroup: UntypedFormGroup,
  ): void {
    const ref = this.modal.open(WorkflowNotificationComponent, {
      size: 'lg',
    });
    const instance = ref.componentInstance as WorkflowNotificationComponent;
    instance.workflowFunction = workflowFunctionGroup.value;
    instance.lifecycleId = this.workflowDeclaration.lifecycleId;
    ref.result.then(
      (newGroup) => {
        newGroup.recipients = newGroup.recipients.map((recipientLine) => {
          const recipient: any = _.pick(recipientLine.performer, [
            'id',
            'name',
            'type',
          ]);

          if (recipient.type === 'user') {
            recipient.userId = recipient.id;
          }

          if (recipient.type === 'group') {
            recipient.groupId = recipient.id;
          }

          if (recipient.type === 'role') {
            recipient.role = recipient.id;
          }

          return {
            ...recipient,
            id: Guid.generate(),
          };
        });

        if (workflowFunctionGroup?.value.id) {
          workflowFunctionGroup.patchValue(newGroup);
          this.patchWorkflowFunction(newGroup);
        } else {
          this.addWorkflowFunction(newGroup);
        }
      },
      () => null,
    );
  }

  /**
   * Edits workflow function transition.
   *
   * @param functionId Function Id.
   * @param actions Actions.
   * @param transitionFormGroup Transition form group.
   */
  public editTransition(
    functionId: string,
    actions: WorkflowAction[],
    transitionFormGroup?: UntypedFormGroup,
  ): void {
    const modalRef = this.modal.open(WorkflowTransitionModalComponent, {
      size: 'md',
    });
    const instance =
      modalRef.componentInstance as WorkflowTransitionModalComponent;

    instance.transitionFormGroup = transitionFormGroup.getRawValue() ?? null;
    instance.actions = actions;
    instance.functionId = functionId;

    modalRef.result.then(
      (newGroup) => {
        let conditionsString = '';
        let nextFunctionString = '';

        if (newGroup.conditions) {
          conditionsString = this.getConditionsString(newGroup.conditions);
        }

        nextFunctionString = this.workflow.functions.find(
          (func) => func.id === newGroup.nextFunctionId,
        )?.name;

        const newTransition = {
          ...newGroup,
          nextFunctionString,
          conditionsString,
          id: Guid.generate(),
        };

        if (newGroup.id) {
          transitionFormGroup.patchValue(newTransition);
        } else {
          this.addTransitionSubject.next(newTransition);
        }
      },
      () => null,
    );
  }

  /**
   * Edits workflow function transition.
   *
   * @param functionId Function Id.
   * @param actions Actions.
   * @param conditionFormGroup Condition form group.
   */
  public editCondition(
    functionId: string,
    conditionFormGroup?: UntypedFormGroup,
  ): void {
    const modalRef = this.modal.open(WorkflowConditionModalComponent, {
      size: 'md',
    });
    const instance =
      modalRef.componentInstance as WorkflowConditionModalComponent;

    instance.conditionFormGroup = conditionFormGroup.getRawValue() ?? null;
    instance.functionId = functionId;

    modalRef.result.then(
      (newGroup) => {
        let conditionsString = '';
        let nextFunctionString = '';

        if (newGroup.conditions) {
          conditionsString = this.getConditionsString(newGroup.conditions);
        }

        nextFunctionString = this.workflow.functions.find(
          (func) => func.id === newGroup.nextFunctionId,
        )?.name;

        const newTransition = {
          ...newGroup,
          nextFunctionString,
          conditionsString,
          id: Guid.generate(),
        };

        if (newGroup.id) {
          conditionFormGroup.patchValue(newTransition);
        } else {
          this.addTransitionSubject.next(newTransition);
        }
      },
      () => null,
    );
  }

  /**
   * Edits workflow function action.
   * @param actionFormGroup Action form group.
   */
  public editAction(actionFormGroup?: UntypedFormGroup) {
    const modalRef = this.modal.open(WorkflowActionModalComponent, {
      size: 'md',
    });
    const instance = modalRef.componentInstance as WorkflowActionModalComponent;
    instance.actionFormGroup = actionFormGroup || null;
    instance.lifecycleId = this.workflowDeclaration.lifecycleId;

    modalRef.result.then(
      (newGroup) => {
        const newAction = {
          ...newGroup,
        };
        if (actionFormGroup) {
          actionFormGroup.patchValue(newAction);
          /** Обновляем transition form */
          const actionForm = actionFormGroup.controls
            .actionForm as UntypedFormGroup;
          const requestedPropertiesArray = actionForm.controls
            .requestedProperties as UntypedFormArray;
          requestedPropertiesArray.clear();
          if (newGroup.actionForm?.requestedProperties) {
            newGroup.actionForm.requestedProperties.map((property) => {
              const requestedPropertyGroup =
                this.getRequestedPropertyFormGroup();
              requestedPropertyGroup.patchValue(property);
              requestedPropertiesArray.push(requestedPropertyGroup);
            });
          }
        } else {
          this.addActionSubject.next(newAction);
        }
      },
      () => null,
    );
  }

  /**
   * Gets workflow schema.
   * @param stateId State Id.
   */
  public getStateName(stateId: string) {
    return this.data
      .collection('States')
      .entity(stateId)
      .get({ select: ['id', 'name'] });
  }

  /* Getting lifecycle states. */
  public getStates(): Observable<NamedEntity[]> {
    return this.data
      .collection('States')
      .query<NamedEntity[]>({
        filter: {
          lifecycleId: {
            type: 'guid',
            value: this.workflowDeclaration.lifecycleId,
          },
        },
      })
      .pipe(share());
  }

  /** Starts detect changes. */
  public detectChanges() {
    this.changesSubject.next();
  }

  /**
   * Saves name.
   * @param name Name.
   */
  public saveName(name: string) {
    return this.data
      .collection('WorkflowDeclarations')
      .entity(this.workflowDeclaration.id)
      .patch({ name });
  }

  /**
   * Updates workflow declaration.
   * @param isActive Is active.
   * @param description Description.
   */
  public updateWorkflowDeclaration(isActive: boolean, description: string) {
    return this.data
      .collection('WorkflowDeclarations')
      .entity(this.workflowDeclaration.id)
      .patch({ isActive, description });
  }

  /**
   * Gets conditions string.
   * @param conditions Workflow transition conditions.
   */
  public getConditionsString(
    conditions: WorkflowTransitionCondition[],
  ): string {
    return conditions.reduce(
      (prev, condition, index) =>
        index === 0
          ? this.translate.instant(
              `settings.workflows.card.transitions.conditionTypes.${condition.conditionType}`,
            )
          : prev +
            `, ${this.translate.instant(
              `settings.workflows.card.transitions.conditionTypes.${condition.conditionType}`,
            )}`,
      '',
    );
  }

  /**
   * Shows validation warning.
   * @param warnings Warnings.
   */
  public showValidationWarning(warnings: Array<string>) {
    if (warnings.length > 0) {
      const exception: Exception = {
        message: this.translate.instant(
          'settings.workflows.card.messages.errors',
        ),
        details: [],
        code: '',
      };
      warnings.forEach((warn) => {
        exception.details.push({
          message: this.translate.instant(warn),
          code: '',
        });
      });

      this.message.errorDetailed(
        exception,
        this.translate.instant('shared.dataNotSaved'),
      );
    }
  }

  /**
   * Gets requested property form group.
   */
  public getRequestedPropertyFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: Guid.generate(),
      name: null,
      nameControl: [null, Validators.required],
      isRequired: false,
    });
  }
}
