import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';

@Component({
  selector: 'tmt-performer-cell',
  template: `<tmt-performer-box [formControl]="control" />`,
  styles: [
    `
      :host {
        display: block;
        overflow: visible !important;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PerformerCellComponent implements GridTemplateCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }
  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.service.detectChanges$.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
}
