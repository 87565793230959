import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActCardService } from '../act-card.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';

@Component({
  selector: 'tmt-act-task-cell',
  templateUrl: './act-task-cell.component.html',
  styleUrls: ['./act-task-cell.component.scss'],
})
export class ActTaskCellComponent
  implements GridTemplateCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public projectId: string;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: GridService,
    private actCardService: ActCardService,
    private ref: ChangeDetectorRef,
  ) {
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }
  ngOnInit(): void {
    this.actCardService.act$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((act) => {
        this.projectId = act.project?.id;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
