import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { AbstractHierarchicalBoxComponent } from 'src/app/shared/components/controls/hierarchical-box/abstract-hierarchical-box.component';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { SubordinatesValue } from 'src/app/shared/components/controls/hierarchical-box/hierarchical-box-value.model';

@Component({
  selector: 'tmt-hierarchical-box',
  templateUrl: './hierarchical-box.component.html',
  styleUrls: ['./hierarchical-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalBoxComponent),
      multi: true,
    },
  ],
})
export class HierarchicalBoxComponent extends AbstractHierarchicalBoxComponent {
  constructor(
    protected override notificationService: NotificationService,
    protected override dataService: DataService,
    protected override translateService: TranslateService,
    protected override cdr: ChangeDetectorRef,
    protected override renderer: Renderer2,
    protected override el: ElementRef<HTMLElement>,
  ) {
    super(
      notificationService,
      dataService,
      translateService,
      cdr,
      renderer,
      el,
    );
  }

  public override writeValue(item: any): void {
    if (this.withHierarchy) {
      this.includeSubElements = item?.includeSubordinates ?? false;
      this.selectedRow = item?.value;
      this.textControl.setValue(item?.value.name ?? '', { emitEvent: false });
    } else {
      this.selectedRow = item;
      this.textControl.setValue(item?.name ?? '', { emitEvent: false });
    }

    this.cdr.detectChanges();
  }

  /** Toggles the display mode of subordinates. */
  public setIncludingSubElements(): void {
    this.includeSubElements = !this.includeSubElements;
    this.propagateChange(this.getFormValue());
  }

  public override onSelected(value: any): void {
    this.selectedRow = value;
    this.propagateChange(this.getFormValue());
    this.setTextBoxValue(value);
    this.cancel();
  }

  private getFormValue(): SubordinatesValue | NamedEntity {
    const value = this.selectedRow
      ? {
          id: this.selectedRow.id,
          name: this.selectedRow.name,
        }
      : null;

    return this.withHierarchy
      ? {
          value,
          includeSubordinates: this.includeSubElements,
        }
      : value;
  }
}
