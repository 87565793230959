import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ProjectBillingModalComponent } from './shared/project-billing-modal/project-billing-modal.component';
import { ProjectBillingToolbarComponent } from './project-billing-toolbar/project-billing-toolbar.component';
import { ProjectBillingComponent } from './project-billing.component';
import { ProjectCardSharedModule } from '../../shared/project-card-shared.module';
import { ProjectRbcSharedModule } from '../shared/project-rbc-shared.module';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [
    ProjectBillingComponent,
    ProjectBillingToolbarComponent,
    ProjectBillingModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    ProjectRbcSharedModule,
    Grid2Module,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.rbc.billing',
          url: '/billing',
          component: ProjectBillingComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ProjectBillingModule {}
