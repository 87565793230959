import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import {
  ProjVersionMergeDetailEntry,
  ProjVersionMergeDetailsDto,
} from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-details-dto.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjVersionMergeDetailTypes } from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-detail-type.model';
import { ProjVersionMergeDetailEntityTypes } from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-detail-entity-type.model';
import { TranslateService } from '@ngx-translate/core';
import { ProjectVersionsMergeModalService } from 'src/app/projects/project-versions/project-versions-merge-modal/core/project-versions-merge-modal.service';
import { Subject } from 'rxjs';
import { Order } from 'src/app/shared/models/inner/order';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid2/models/grid-column.interface';

/**
 * Represents the MergeUpdateDetails Merge tool step Change details tab content.
 * */
@Component({
  selector: 'wp-project-version-merge-details-updates',
  templateUrl: './project-version-merge-details-updates.component.html',
  styleUrls: ['./project-version-merge-details-updates.component.scss'],
  providers: [GridService],
})
export class ProjectVersionMergeDetailsUpdatesComponent
  implements OnInit, OnDestroy
{
  formArray = new UntypedFormArray([]);

  public readonly projectVersionsMergeModalKey =
    'projects.projectVersions.projectVersionsMergeModal';

  gridOptions: Grid2Options = {
    sorting: true,
    selectionType: SelectionType.row,
    view: {
      name: 'mergeDetailsUpdates',
      columns: [
        {
          name: 'entityType',
          header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.detailsColumns.entityType`,
          hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.detailsColumns.entityType`,
          type: GridColumnType.String,
          width: '100%',
        },
        {
          name: 'description',
          header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.detailsColumns.description`,
          hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.detailsColumns.description`,
          type: GridColumnType.String,
          width: '100%',
        },
        {
          name: 'type',
          header: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.detailsColumns.type`,
          hint: `${this.projectVersionsMergeModalKey}.steps.mergeUpdateDetails.detailsColumns.type`,
          type: GridColumnType.String,
          width: '100%',
        },
      ],
      order: {
        column: 'entityType',
        reverse: true,
      },
    },
  };

  /** The merge detail DTO. */
  private mergeDetailsDto: ProjVersionMergeDetailsDto;

  /** The available merge detail types. */
  private detailTypes = ProjVersionMergeDetailTypes;

  /** The available merge detail entity types. */
  private detailEntityTypes = ProjVersionMergeDetailEntityTypes;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public mergeModalService: ProjectVersionsMergeModalService,
    translate: TranslateService,
    private gridService: GridService,
    private fb: UntypedFormBuilder,
  ) {
    this.detailTypes.forEach((x) => (x.name = translate.instant(x.name)));
    this.detailEntityTypes.forEach((x) => (x.name = translate.instant(x.name)));
  }

  ngOnInit(): void {
    this.mergeModalService.mergeUpdateDetails$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((details) => {
        this.mergeDetailsDto = details.dto;
        this.fillForm(details.dto?.detail.entries);
      });
    this.gridService.order$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((order) => this.orderChanged(order));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Fills the Project versions Merge Change details form group.
   *
   * @param entries The Change detail entries.
   * @param reorder
   * The entries reorder flag.
   * True - reorder the entries.
   * False - fill the entries and set the default order.
   * */
  private fillForm(
    entries: Array<ProjVersionMergeDetailEntry>,
    reorder = false,
  ) {
    this.formArray.clear();
    entries?.forEach((entry) => {
      const typeName = !reorder
        ? this.detailTypes.find((x) => x.code === entry.type)?.name
        : entry.type;
      const entityTypeName = !reorder
        ? this.detailEntityTypes.find((x) => x.code === entry.entityType)?.name
        : entry.entityType;
      const group = this.fb.group({
        id: Guid.generate(),
        type: typeName,
        entityType: entityTypeName,
        description: entry.description,
      });
      this.formArray.push(group);
    });
    if (!reorder) {
      this.gridService.setOrder(this.gridOptions.view.order);
    }
    this.gridService.detectChanges();
  }

  /**
   * The grid column order change handler.
   * Reorders the rows with the new order.
   *
   * @param order The new order.
   * */
  private orderChanged(order: Order) {
    const entries =
      this.formArray.getRawValue() as Array<ProjVersionMergeDetailEntry>;
    const sortedEntries = entries.sort((a, b) =>
      ProjectVersionMergeDetailsUpdatesComponent.fnCompareForSort(order, a, b),
    );
    this.fillForm(sortedEntries, true);
  }

  /**
   * Compares the Change detail entries in sorting.
   * Uses the order to get the column and the order direction in sorting.
   *
   * @param order The order.
   * @param a The first entry.
   * @param b The second entry.
   * */
  private static fnCompareForSort(
    order: Order,
    a: ProjVersionMergeDetailEntry,
    b: ProjVersionMergeDetailEntry,
  ) {
    const fieldA = a[order.column];
    const fieldB = b[order.column];

    if (fieldA < fieldB) {
      return order.reverse ? -1 : 1;
    } else {
      return order.reverse ? 1 : -1;
    }
  }
}
