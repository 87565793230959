import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  inject,
  DestroyRef,
} from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Constants } from 'src/app/shared/globals/constants';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CalculatedField } from 'src/app/shared/models/entities/analytics/calculated-field.model';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { ReportField } from '../../shared/models/source-description/report-field.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { CalculatedFieldTypes } from '../../shared/models/report-field-type.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { uniq } from 'lodash';
import { Exception } from 'src/app/shared/models/exception';
import { filter } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import {
  GridColumnType,
  GridSelectControlColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';

@Component({
  selector: 'wp-calculated-field-card',
  templateUrl: './calculated-field-card.component.html',
  providers: [GridService],
})
export class CalculatedFieldCardComponent implements OnInit {
  @Input() entityId: string;

  @ViewChild('editor') editor: ElementRef;

  public state: CardState;
  public isSaving = false;

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: [false],
    fieldType: [null, Validators.required],
    reportType: [null],
    localStrings: this.fb.array([]),
    expression: '',
    sourceField: null,
  });

  public readonly: boolean;
  public sourceFields: ReportField[];
  public fieldTypes: NamedEntity[];
  public languages: NamedEntity[];

  public get localStrings(): UntypedFormArray {
    return this.form.controls['localStrings'] as UntypedFormArray;
  }

  public gridOptions: Grid2Options = {
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.localStrings.removeAt(index);
          this.localStrings.markAsDirty();
        },
      },
    ],
    view: {
      name: 'strings',
      columns: [
        <GridSelectControlColumn>{
          name: 'language',
          header: 'analytics.calculatedFields.card.properties.language.label',
          hint: 'analytics.calculatedFields.card.properties.language.label',
          type: GridColumnType.SelectControl,
          placeholder:
            'analytics.calculatedFields.card.properties.language.label',
          values: () => this.languages,
          width: '250px',
        },
        <GridStringControlColumn>{
          name: 'label',
          header: 'analytics.calculatedFields.card.properties.title.label',
          hint: 'analytics.calculatedFields.card.properties.title.label',
          type: GridColumnType.StringControl,
          placeholder:
            'analytics.calculatedFields.card.properties.title.placeholder',
          width: '100%',
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private analyticsService: AnalyticsService,
    private gridService: GridService,
  ) {}

  public addString() {
    const group = this.fb.group({
      id: Guid.generate(),
      label: [
        null,
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      language: ['', Validators.required],
    });
    this.localStrings.push(group);
    this.gridService.selectGroup(group);
  }

  // Загрузка данных.
  private load = () => {
    this.form.reset();
    this.state = CardState.Loading;
    this.actionService.action('save').hide();
    this.form.markAsPristine();
    this.form.markAsUntouched();

    const query = {
      expand: { reportType: { select: ['id', 'name', 'code'] } },
    };

    // Загрузка данных.
    this.data
      .collection('CalculatedFields')
      .entity(this.entityId)
      .get<CalculatedField>(query)
      .subscribe({
        next: (field) => {
          this.form.patchValue(field);
          this.readonly = !field.editAllowed;
          this.readonly ? this.form.disable() : this.form.enable();

          this.analyticsService
            .getSourceDescription(field.reportType.code)
            .subscribe((sourceDescription) => {
              this.sourceFields = [];
              sourceDescription.allFields.forEach((reportField) => {
                if (reportField.isCalculated) {
                  return;
                }
                this.sourceFields.push(reportField);
              });
            });

          if (field.fieldType) {
            const filedType = this.fieldTypes.find(
              (f) => f.id === field.fieldType,
            );
            this.form.controls['fieldType'].setValue(filedType);
          }

          const titleLocalization = JSON.parse(
            field.titleLocalization,
          ) as Dictionary<string>;

          // Загрузка языков
          this.data.model
            .function('GetLanguages')
            .query<NamedEntity[]>()
            .subscribe((languages) => {
              this.languages = languages;
              this.localStrings.clear();

              for (const culture in titleLocalization) {
                if (
                  Object.prototype.hasOwnProperty.call(
                    titleLocalization,
                    culture,
                  )
                ) {
                  const lang = languages.find((l) => l.id === culture);

                  this.localStrings.push(
                    this.fb.group({
                      id: Guid.generate(),
                      label: [
                        titleLocalization[culture],
                        [
                          Validators.required,
                          Validators.maxLength(Constants.formNameMaxLength),
                        ],
                      ],
                      language: [lang, Validators.required],
                    }),
                  );
                }
              }
              this.state = CardState.Ready;
              this.actionService.action('save').isShown = !this.readonly;
            });
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  // Сохранение данных.
  public save = (): void => {
    this.form.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const formData = this.form.value;

    // Проверить уникальность строк локализации.
    const localStrings = formData.localStrings as any[];
    if (
      uniq(localStrings.map((ls) => ls.language.id)).length !==
      localStrings.length
    ) {
      this.notification.warningLocal(
        'analytics.calculatedFields.card.messages.singleTitlePerLanguageIsAllowed',
      );
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const titleLocalization: Dictionary<string> = {};

    (<any[]>formData.localStrings).forEach((localString) => {
      titleLocalization[localString.language.id] = localString.label;
    });

    const data: any = {
      id: this.entityId,
      name: formData.name,
      description: formData.description,
      isActive: formData.isActive,
      fieldType: formData.fieldType.id,
      expression: formData.expression,
      titleLocalization: JSON.stringify(titleLocalization),
    };

    this.data
      .collection('CalculatedFields')
      .entity(this.entityId)
      .update(data)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'analytics.calculatedFields.card.messages.fieldIsSaved',
          );
        },
        error: (error: any) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  // Добавить имя поля в редактор.
  public addField(fieldName: string): void {
    fieldName = `[${fieldName}]`;

    const domElement = this.editor.nativeElement as HTMLTextAreaElement;
    if (domElement.selectionStart || domElement.selectionStart === 0) {
      const startPos = domElement.selectionStart;
      const endPos = domElement.selectionEnd;
      const scrollTop = domElement.scrollTop;
      domElement.value =
        domElement.value.substring(0, startPos) +
        fieldName +
        domElement.value.substring(endPos, domElement.value.length);
      domElement.focus();
      domElement.selectionStart = startPos + fieldName.length;
      domElement.selectionEnd = startPos + fieldName.length;
      domElement.scrollTop = scrollTop;
    } else {
      domElement.value += fieldName;
      domElement.focus();
    }

    this.form.patchValue({ sourceField: null, expression: domElement.value });
    this.form.markAsDirty();
  }

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.fieldTypes = [];

    for (const reportFieldType of CalculatedFieldTypes) {
      this.fieldTypes.push({
        id: reportFieldType,
        name: this.translate.instant(`enums.fieldType.${reportFieldType}`),
      });
    }
    this.load();

    this.form
      .get('sourceField')
      .valueChanges.pipe(
        filter((v) => v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value: ReportField) => {
        this.addField(value.name);
      });
  }
}
