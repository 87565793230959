import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ContactInteractionFilterService } from 'src/app/contacts/card/contact-interaction/contact-interaction-filter/contact-interaction-filter.service';
import { ContactInteractionService } from 'src/app/contacts/card/contact-interaction/contact-interaction.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Component({
  selector: 'tmt-contact-interaction',
  templateUrl: './contact-interaction.component.html',
  styleUrl: './contact-interaction.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: FilterService, useClass: ContactInteractionFilterService },
    ContactInteractionService,
  ],
})
export class ContactInteractionComponent {
  public checkboxControl = new FormControl<boolean>(
    this.contactInteractionService.getShownOnlyPlanned(),
  );

  constructor(
    public readonly contactInteractionService: ContactInteractionService,
    public readonly contactInteractionFilterService: FilterService,
    private readonly actionPanelService: ActionPanelService,
  ) {
    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.contactInteractionService.reload();
    });

    this.checkboxControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((v) => {
        this.contactInteractionService.setShowOnlyPlanned(v);
      });
  }
}
