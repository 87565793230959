import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Injector,
  Input,
  SimpleChanges,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { ContactInteractionService } from 'src/app/contacts/card/contact-interaction/contact-interaction.service';
import { Interaction } from 'src/app/contacts/model/interaction.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';

@Component({
  selector: 'tmt-contact-interaction-item',
  templateUrl: './contact-interaction-item.component.html',
  styleUrl: './contact-interaction-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInteractionItemComponent {
  @Input() public interaction: Interaction;
  @Input() public index: number;

  public maxWidth = '100%';
  public maxWidthContact = '100%';
  public readonly contactInteractionService = inject(ContactInteractionService);

  private readonly infoPopupService = inject(InfoPopupService);
  private readonly injector = inject(Injector);
  private readonly stateService = inject(StateService);
  private readonly elementRef = inject(ElementRef<HTMLElement>);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['interaction']) {
      this.setMaxWidth();
    }
  }

  /** Open user info. */
  public openUserInfo(): void {
    const userId = this.interaction.performer.id;
    const target = this.elementRef.nativeElement.querySelector(
      `#performer${this.index}`,
    );
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Changes state to lead profile. */
  public openLead(): void {
    this.stateService.go('lead.profile', {
      entityId: this.interaction.lead.id,
    });
  }

  /** Changes state to client overview kpi. */
  public openClient(): void {
    this.stateService.go('client.overview.kpi', {
      entityId: this.interaction.organization.id,
      navigation: 'clients.allClients',
    });
  }

  /**
   * Changes state to contact profile.
   *
   * @param id contact ID
   */
  public openContact(id: string): void {
    this.stateService.go('contact.profile', {
      entityId: id,
    });
  }

  /** Sets interaction in edit mode. */
  public enableEditMode(): void {
    this.contactInteractionService.setActiveInteraction(this.interaction.id);
  }

  /** Sets interaction in view mode. */
  public onCancel(): void {
    this.contactInteractionService.setActiveInteraction(null);
  }

  /** Checks if interaction has detailed info */
  public hasDetailedInfo(): boolean {
    return !!(
      this.interaction.lead?.name ||
      this.interaction.interactionContacts?.length ||
      this.interaction.organization?.name
    );
  }

  /** Sets values for css max-width properties. */
  private setMaxWidth(): void {
    let visibleSections = 0;

    if (this.interaction.organization?.name) visibleSections++;
    if (this.interaction.lead?.name) visibleSections++;
    if (this.interaction.interactionContacts?.length) visibleSections++;

    if (visibleSections > 0) {
      this.maxWidth = `${100 / visibleSections}%`;
    }

    if (this.interaction.interactionContacts?.length) {
      this.maxWidthContact = `${100 / this.interaction.interactionContacts.length}%`;
    }
  }
}
