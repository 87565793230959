import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { EmployeeTimeOffBalanceComponent } from 'src/app/employees/card/employee-time-off-balance/employee-time-off-balance.component';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [EmployeeTimeOffBalanceComponent],
  imports: [CommonModule, SharedModule, Grid2Module],
  exports: [EmployeeTimeOffBalanceComponent],
})
export class EmployeeTimeOffBalanceModule {}
