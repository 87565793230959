import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Transition } from '@uirouter/angular';
import { InvoiceListComponent } from './list/invoice-list.component';
import {
  VIEW_NAME,
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  META_ENTITY_TYPE,
} from 'src/app/shared/tokens';
import { INVOICE_LIST } from 'src/app/shared/lists/invoice.list';
import { SharedModule } from 'src/app/shared/shared.module';
import { InvoiceFilterComponent } from './list/invoice-filter/invoice-filter.component';
import { InvoiceCardComponent } from './card/invoice-card.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { InvoiceTimeLinesComponent } from './card/invoice-time-lines/invoice-time-lines.component';
import { AddingLinesModalComponent } from './card/adding-lines-modal/adding-lines-modal.component';
import { GroupingSettingsComponent } from './card/adding-lines-modal/grouping-settings/grouping-settings.component';
import { InvoiceValueCellComponent } from './card/shared/invoice-value-cell/invoice-value-cell.component';
import { InvoiceValueReadonlyCellComponent } from './card/shared/invoice-value-cell/invoice-value-readonly-cell.component';
import { InvoiceAmountCellComponent } from './card/shared/invoice-amount-cell/invoice-amount-cell.component';
import { InvoiceCreationComponent } from './creation/invoice-creation.component';
import { InvoiceExpenseLinesComponent } from './card/invoice-expense-lines/invoice-expense-lines.component';
import { InvoiceCustomLinesComponent } from './card/invoice-custom-lines/invoice-custom-lines.component';
import { InvoiceTaskCellComponent } from './card/shared/invoice-task-cell/invoice-task-cell.component';
import { InvoiceDescriptionCellComponent } from './card/shared/invoice-description-cell/invoice-description-cell.component';
import { InvoiceDescriptionReadonlyCellComponent } from './card/shared/invoice-description-cell/invoice-description-readonly-cell.component';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [
    InvoiceListComponent,
    InvoiceFilterComponent,
    InvoiceCardComponent,
    InvoiceTimeLinesComponent,
    InvoiceExpenseLinesComponent,
    InvoiceCustomLinesComponent,
    AddingLinesModalComponent,
    GroupingSettingsComponent,
    InvoiceValueCellComponent,
    InvoiceValueReadonlyCellComponent,
    InvoiceDescriptionCellComponent,
    InvoiceDescriptionReadonlyCellComponent,
    InvoiceAmountCellComponent,
    InvoiceCreationComponent,
    InvoiceTaskCellComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    Grid2Module,
    UIRouterModule.forChild({
      states: [
        {
          name: 'invoices',
          url: '/invoices/{view:viewName}?{navigation}&{route}',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: null,
            routeMode: null,
            view: null,
          },
          component: InvoiceListComponent,
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: META_ENTITY_TYPE, useValue: 'Invoice' },
            { provide: LIST, useValue: INVOICE_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'invoice',
                queryData: {
                  select: ['id', 'stateId', 'name'],
                  expand: { state: { select: 'isEntityProtected' } },
                },
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'invoice',
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          url: '/invoices/{entityId:guid}?{navigation}&{route}',
          component: InvoiceCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
      ],
    }),
    BaseSharedModule,
    CommentsModule,
  ],
})
export class InvoicesModule {}
