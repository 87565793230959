import { ChangeDetectorRef, Component } from '@angular/core';
import { ProjectRevenueEstimatesService } from '../project-revenue-estimates.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';

@Component({
  selector: 'wp-project-revenue-toolbar',
  templateUrl: './project-revenue-toolbar.component.html',
})
export class ProjectRevenueToolbarComponent extends AbstractToolbar {
  constructor(
    public revenueEstimatesService: ProjectRevenueEstimatesService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public addEntry() {
    this.gridService.execute('addEntry');
  }

  public recognize() {
    this.gridService.execute('recognize');
  }
}
