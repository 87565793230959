import { Component, Input, ViewRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';

@Component({
  selector: 'wp-financial-non-nullable-date-cell',
  templateUrl: './financial-non-nullable-date-cell.component.html',
})
export class FinancialNonNullableDateCellComponent implements GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  constructor(
    public service: GridService,
    private ref: ChangeDetectorRef,
  ) {
    this.service.detectChanges$.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }
}
