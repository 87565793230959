import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Validators,
  UntypedFormBuilder,
  FormArray,
  FormGroup,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { BoardCardService } from 'src/app/settings-app/boards/card/board-card.service';
import { BoardCardPermissionsComponent } from 'src/app/settings-app/boards/card/permissions/permissions.component';
import { Constants } from 'src/app/shared/globals/constants';
import {
  Board,
  BoardNavigation,
  BoardTeamMember,
} from 'src/app/settings-app/boards/model/board.model';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

@Component({
  selector: 'tmt-board-card',
  templateUrl: './board-card.component.html',
  providers: [BoardCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardCardComponent implements OnInit {
  @ViewChild(BoardCardPermissionsComponent)
  private permissionsComponent: BoardCardPermissionsComponent;

  @Input() entityId: string;

  public readonly: boolean;
  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    entityType: ['', Validators.required],
    isActive: false,
    team: new FormArray([]),
    navigations: new FormArray([]),
  });
  public isSaving$ = new BehaviorSubject<boolean>(false);
  public team: BoardTeamMember[];
  public navigationResponse: BoardNavigation[];

  private destroyRef = inject(DestroyRef);

  public get permissions(): FormArray {
    return this.form.get('team') as FormArray;
  }

  public get navigations(): FormArray {
    return this.form.get('navigations') as FormArray;
  }

  constructor(
    public boardCardService: BoardCardService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private appService: AppService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.actionPanelService.set([
      {
        title: 'shared2.actions.save',
        hint: 'shared2.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.form
      .get('name')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.boardCardService.updateName(value);
      });

    this.readonly = !this.appService.checkEntityPermission(
      'Board',
      PermissionType.Modify,
    );
    this.actionPanelService.action('save').isShown = !this.readonly;

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.boardCardService.board$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((board: Board) => {
        this.form.patchValue({
          id: board.id,
          name: board.name,
          isActive: board.isActive,
          entityType: board.entityType,
        });

        board.team.forEach(
          (item) =>
            (item.name =
              item.user?.name ?? item.group?.name ?? item.permissionSet?.name),
        );
        this.team = board.team;
        this.permissionsComponent.setFormTeam(this.team);
        this.navigationResponse = board.navigations.sort(naturalSort('area'));
        this.readonly ? this.form.disable() : this.form.enable();
        this.form.get('entityType').disable();
      });
  }

  /** Saves board. */
  public save(): void {
    this.form.markAllAsTouched();
    this.validateNavigations();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);
    this.actionPanelService.action('save').start();

    this.dataService
      .collection('Boards')
      .entity(this.entityId)
      .patch({
        name: this.form.value.name,
        isActive: this.form.value.isActive,
        navigations: this.navigations.value.map(
          (item) => (item = { area: item.area.key, group: item.group.key }),
        ),
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.permissionsComponent.saveBoardTeam();
          this.navigationService.reloadCustomNavigationItems();
        },
        error: (error: any) => {
          this.isSaving$.next(false);
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /* Reloads view. */
  private reload(): void {
    if (!this.form.dirty) {
      this.form.markAsPristine();
      this.boardCardService.load();
    } else {
      this.messageService
        .confirmLocal('shared2.messages.leavePageMessage')
        .then(
          () => {
            this.form.markAsPristine();
            this.boardCardService.load();
          },
          () => null,
        );
    }
  }

  /** Validates navigations before saving. */
  private validateNavigations(): void {
    this.navigations.controls.forEach((formGroup: FormGroup) => {
      if (!formGroup.value.area) {
        formGroup.get('area').setValidators(Validators.required);
        formGroup.get('area').updateValueAndValidity();
      }
      if (!formGroup.value.group && formGroup.get('group').enabled) {
        formGroup.get('group').setValidators(Validators.required);
        formGroup.get('group').updateValueAndValidity();
      }
    });
  }
}
