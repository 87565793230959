import {
  Component,
  Input,
  ViewRef,
  ChangeDetectorRef,
  Inject,
} from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { FinancialTaskCellService } from './financial-task-cell.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-financial-task-cell',
  templateUrl: './financial-task-cell.component.html',
  styleUrls: ['./financial-task-cell.component.scss'],
})
export class FinancialTaskCellComponent implements GridTemplateCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  public projectVersion: ProjectVersion;

  constructor(
    public service: GridService,
    private financialTaskCellService: FinancialTaskCellService,
    private projectTasksService: ProjectTasksService,
    private ref: ChangeDetectorRef,
    @Inject('entityId') public entityId,
  ) {
    this.service.detectChanges$.subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
    this.projectVersion = this.financialTaskCellService.projectVersion;
  }

  public toggle() {
    this.financialTaskCellService.toggleTask(this.formGroup.value.id);
  }

  getIndent() {
    const width = 30 * this.formGroup.value.indent;

    const value = {
      'padding-left': width + 'px',
    };
    return value;
  }

  public getTaskFullName(): string {
    return this.projectTasksService.getTaskFullName(
      this.formGroup.value.projectTask,
    );
  }
}
