import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { UntypedFormBuilder } from '@angular/forms';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MessageService } from 'src/app/core/message.service';
import { Subject } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { filter, takeUntil } from 'rxjs/operators';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { RoleName } from 'src/app/shared/models/enums/role-name.enum';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wp-user-access',
  templateUrl: './user-access.component.html',
})
export class UserAccessComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public isLoading: boolean;
  private _isSaving: boolean;

  destroyed$ = new Subject<void>();

  public get isSaving() {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUI.start();
      this.actionService.action('save').start();
    } else {
      this.actionService.action('save').stop();
      this.blockUI.stop();
    }
  }

  readonly = !this.app.checkEntityPermission(
    'UserPermissionSet',
    PermissionType.Modify,
  );

  roles: NamedEntity[] = [];

  accessForm = this.fb.array([]);

  constructor(
    private notification: NotificationService,
    private message: MessageService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    translate: TranslateService,
  ) {
    for (const role of Object.keys(RoleName)) {
      if (role == RoleName.ClientManager && environment.production) {
        continue;
      }

      this.roles.push({
        name: translate.instant(`enums.role.${role}`),
        id: role,
      });
    }
  }

  ngOnInit() {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    if (!this.readonly) {
      this.actionService.action('save').show();
    }

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());

    this.roles.forEach((role: any) => {
      role.query = { filter: [{ roleName: role.id }] };
      this.accessForm.push(this.fb.control(null));
    });

    this.load();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private reload() {
    if (!this.accessForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  load = () => {
    this.isLoading = true;
    this.actionService.action('save').hide();

    const params = {
      select: ['id'],
      expand: [{ permissionSet: { select: ['id', 'name', 'roleName'] } }],
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .collection('PermissionSets')
      .query<object[]>(params)
      .subscribe({
        next: (data) => {
          this.roles.forEach((role: any, index: number) => {
            const loadedUserSet: any = data.find(
              (userSet: any) => userSet.permissionSet.roleName === role.id,
            );
            this.accessForm
              .at(index)
              .setValue(loadedUserSet ? loadedUserSet.permissionSet : null);
          });

          this.readonly ? this.accessForm.disable() : this.accessForm.enable();
          this.accessForm.markAsPristine();
          this.accessForm.markAsUntouched();
          this.actionService.action('save').isShown = !this.readonly;
          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  };

  // Сохранение сущности.
  save() {
    this.isSaving = true;
    const data = { permissionSets: [] as any };
    const sets = this.accessForm.value as any[];
    sets.forEach((set: any) => {
      if (!set) {
        return;
      }
      data.permissionSets.push({
        userId: this.entityId,
        permissionSetId: set.id,
        id: Guid.generate(),
      });
    });

    this.data
      .collection('Users')
      .entity(this.entityId)
      .action('WP.UpdatePermissionSets')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.accessForm.markAsPristine();
          this.isSaving = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }
}
