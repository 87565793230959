import { Inject, Injectable, Injector } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';

import { assign, findIndex, minBy, orderBy, uniq } from 'lodash';
import { DateTime } from 'luxon';
import { forkJoin, Subject } from 'rxjs';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Exception } from 'src/app/shared/models/exception';
import { ExpenseEstimateModalComponent } from './shared/expense-estimate-modal/expense-estimate-modal.component';
import { ExpenseEstimateLine } from './shared/model/expense-estimate-line.model';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { FinancialTaskCellService } from '../../shared/financial-task-cell/financial-task-cell.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { StateService } from '@uirouter/core';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ProjectExpenseEstimatesToolbarComponent } from './project-expense-estimates-toolbar/project-expense-estimates-toolbar.component';
import { ExpenseEstimatesSettings } from './shared/model/expense-estimates-settings.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectExpenseEstimate } from 'src/app/shared/models/entities/projects/project-expense-estimate.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectCardService } from '../../core/project-card.service';
import { GridCurrencyColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { RuleCalculationMethod } from 'src/app/shared/models/enums/rule-calculation-method.enum';
import { FinancialAccountsService } from 'src/app/core/financial-accounts.service';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import {
  Command,
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Injectable()
export class ProjectExpenseEstimatesService {
  get isWorkProjectVersion() {
    return this._isWorkProjectVersion;
  }

  public gridOptions: Grid2Options = {
    resizableColumns: true,
    selectionType: SelectionType.row,
    toolbar: ProjectExpenseEstimatesToolbarComponent,
    commands: [
      {
        name: 'addEntry',
        handlerFn: () => this.addEntry(),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'edit',
        handlerFn: (group: UntypedFormGroup) => this.edit(group),
        allowedFn: (row: any) => row && !row.isTaskGroup,
      },
      {
        name: 'delete',
        handlerFn: (row: any) => this.deleteEntry(row.id),
        allowedFn: (row: any) =>
          !this.readonly && row && !row.isTaskGroup && !row.isRuleReadonly,
      },
      {
        name: 'createRequest',
        handlerFn: (group: UntypedFormGroup) => this.createRequest(group),
        allowedFn: (row: any) =>
          this._isWorkProjectVersion && row && !row.isTaskGroup,
      },
      {
        name: 'clear',
        handlerFn: () => this.clear(),
        allowedFn: () => !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !formGroup.value.isTaskGroup,
        handlerFn: (formGroup: UntypedFormGroup) => this.edit(formGroup),
      },
      {
        name: 'createRequest',
        label: 'projects.projects.card.expenseEstimates.actions.createRequest',
        allowedFn: (formGroup: UntypedFormGroup) =>
          this._isWorkProjectVersion && !formGroup.value.isTaskGroup,
        handlerFn: (group: UntypedFormGroup) => this.createRequest(group),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly &&
          !formGroup.value.isTaskGroup &&
          !formGroup.value.isRuleReadonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteEntry(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  public settings: ExpenseEstimatesSettings;

  public commands: Command[];

  public totals: Dictionary<number> = {};

  public readonly: boolean;

  public formArray: UntypedFormArray = this.fb.array([]);

  private storageSettingsName = 'projectExpenseEstimatesSettings';

  private entries: ProjectExpenseEstimate[] = [];
  private mainTask: ProjectTask;
  private tasks: ProjectTask[] = [];
  private allTasks: ProjectTask[] = [];

  /** Task amounts. */
  private tasksAmounts: Dictionary<number> = {};

  private project: Project;

  private _isWorkProjectVersion: boolean;

  /** Component subscriptions cancel subject. */
  public reloaded$ = new Subject<void>();
  private destroyed$ = new Subject<void>();

  private getCollection = () => this.data.collection('ProjectExpenseEstimates');

  constructor(
    @Inject('entityId') public entityId: string,
    private injector: Injector,
    public autosave: SavingQueueService,
    public app: AppService,
    private state: StateService,
    public modal: NgbModal,
    private blockUI: BlockUIService,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
    public listService: ListService,
    public gridService: GridService,
    public notification: NotificationService,
    public financialAccountsService: FinancialAccountsService,
    private localStorageService: LocalStorageService,
    private translate: TranslateService,
    private projectTasksService: ProjectTasksService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    financialTaskCellService: FinancialTaskCellService,
    private projectCardService: ProjectCardService,
    private messageService: MessageService,
  ) {
    financialTaskCellService.toggleTaskId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((id) => {
        this.toggleTask(id);
      });
    financialTaskCellService.projectVersion =
      this.versionCardService.projectVersion;
  }

  /**
   * Inits service subscriptions.
   */
  init() {
    this.settings = this.localStorageService.retrieve(this.storageSettingsName);
    if (!this.settings) {
      this.settings = {
        grouping: 'none',
      };
      this.localStorageService.store(this.storageSettingsName, this.settings);
    }

    this.projectCardService.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this.project = project;
        this.readonly =
          !project.expenseEstimateEditAllowed ||
          !this.versionCardService.projectVersion.editAllowed;
        this._isWorkProjectVersion =
          this.versionCardService.isWorkProjectVersion();
        if (this.readonly) {
          this.formArray.disable();
          this.gridService.detectChanges();
        }
        const amountColumn = this.gridOptions.view.columns.find(
          (column) => column.name === 'amount',
        ) as GridCurrencyColumn;
        amountColumn.currencyCode = this.project.currency.alpha3Code;
      });

    this.autosave.error$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.load());

    this.load();
    this.loadMainTask();
  }

  /**
   * Disposes all subscriptions.
   * */
  public dispose() {
    this.destroyed$.next();
    this.reloaded$.next();
  }

  /**
   * Toggles task group (expand/collapse).
   *
   * @param taskId Task ID.
   */
  public toggleTask(taskId: string) {
    const task = this.tasks.find((t) => t.id === taskId);
    task.isExpanded = !task.isExpanded;
    this.updateFormArray();
  }

  /**
   * Loads Main task.
   * */
  public loadMainTask() {
    this.projectTasksService
      .getProjectTasks(this.entityId, this.versionCardService.projectVersion)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (tasks: ProjectTask[]) => {
          this.mainTask =
            tasks?.length > 0 ? tasks.find((t) => !t.leadTaskId) : null;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Loads data.
   * */
  public load(): void {
    this.reloaded$.next();
    this.projectTasksService.resetProjectTasks(
      this.entityId,
      this.versionCardService.projectVersion,
    );
    this.autosave.save().then(() => {
      this.formArray.clear();
      this.totals = null;
      this.gridService.setLoadingState(true);

      forkJoin({
        entries: this.getCollection().query<ProjectExpenseEstimate[]>(
          this.getQuery(),
        ),
        tasks: this.projectTasksService.getProjectTasks(
          this.entityId,
          this.versionCardService.projectVersion,
        ),
      })
        .pipe(takeUntil(this.reloaded$))
        .subscribe({
          next: (value) => {
            this.entries = value.entries;

            this.entries.forEach((e) => {
              e.added = 0;
            });

            this.allTasks = value.tasks;

            this.updateTasks();
            this.calculateTotals();
            this.updateFormArray();

            this.gridService.setLoadingState(false);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.gridService.setLoadingState(false);
          },
        });
    });
  }

  /**
   * Adds new Expense estimate entity row to grid.
   * Saves it in queue.
   * */
  public addEntry() {
    this.financialAccountsService.expensesRequestAccounts$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((types) => {
        if (types.length === 0) {
          this.message.errorLocal(
            'projects.projects.card.expenseEstimates.typesNotFound',
          );
          return;
        }

        const account = types.length > 0 ? types[0] : null;
        const entry: ProjectExpenseEstimate = {
          projectTask:
            this.gridService.selectedGroupValue?.projectTask ?? this.mainTask,
          account,
          date: DateTime.now().toISODate(),
          amount: 0,
          id: Guid.generate(),
          description: '',
          added: new Date().getTime(),
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.versionCardService.projectVersion,
          entry,
          this.entityId,
        );

        this.entries.push(entry);

        this.updateTasks();
        this.calculateTotals();
        this.updateFormArray();

        const indexOfNewEntry = (this.formArray.value as any[]).findIndex(
          (l) => l.id === entry.id,
        );

        this.gridService.selectGroup(
          this.formArray.at(indexOfNewEntry) as UntypedFormGroup,
        );

        const data = {
          projectTaskId: entry.projectTask.id,
          accountId: entry.account?.id,
          date: entry.date,
          amount: entry.amount,
          id: entry.id,
          description: entry.description,
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.versionCardService.projectVersion,
          data,
          this.entityId,
        );

        this.autosave.addToQueue(
          Guid.generate(),
          this.getCollection().insert(data),
        );
      });
  }

  /**
   * Deletes Expense estimate entity.
   *
   * @param id Entity ID.
   * */
  public deleteEntry(id: string): void {
    this.entries = this.entries.filter((l) => l.id !== id);
    this.updateTasks();
    this.calculateTotals();
    this.updateFormArray();
    this.autosave.addToQueue(id, this.getCollection().entity(id).delete());
  }

  /**
   * Opens modal window to view/edit Expense estimate entity.
   *
   * @param group Expense estimate form group.
   * */
  public edit(group: UntypedFormGroup) {
    const ref = this.modal.open(ExpenseEstimateModalComponent, {
      injector: this.injector,
    });
    const instance = ref.componentInstance as ExpenseEstimateModalComponent;

    const groupValue: ProjectExpenseEstimate & { isRuleReadonly: boolean } =
      group.getRawValue();
    instance.entry = groupValue;
    instance.readonly = this.readonly || groupValue.isRuleReadonly;
    instance.projectId = this.entityId;
    instance.projectCurrencyCode = this.project.currency.alpha3Code;
    instance.projectVersion = this.versionCardService.projectVersion;

    ref.result.then(
      (result) => {
        group.patchValue(result);
      },
      () => null,
    );
  }

  /**
   * Creates Form group line.
   *
   * @returns Form group line.
   * */
  public getLineGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: null,
      date: [null, Validators.required],
      amount: [null, Validators.required],
      description: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      account: [null, Validators.required],
      expenseRule: null,
      projectTask: [null, Validators.required],
      indent: 0,
      isRuleEntry: false,
      isRuleReadonly: false,
      isTaskGroup: false,
      isExpanded: true,
    });

    group.valueChanges
      .pipe(takeUntil(this.reloaded$))
      .subscribe((line: ExpenseEstimateLine) => {
        const entry = this.entries.find((e) => e.id === line.id);
        assign(entry, line);

        const data = {
          projectTaskId: entry.projectTask.id,
          accountId: entry.account?.id,
          expenseRuleId: entry.expenseRule?.id,
          date: entry.date,
          amount: entry.amount,
          id: entry.id,
          description: entry.description,
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.versionCardService.projectVersion,
          data,
          this.entityId,
        );

        this.autosave.addToQueue(
          entry.id,
          this.getCollection().entity(entry.id).update(data),
        );
      });

    group.controls['amount'].valueChanges
      .pipe(takeUntil(this.reloaded$))
      .subscribe(() => {
        // Find lead task.
        const getLeadTask = (task: ProjectTask): ProjectTask => {
          const leadTask = this.tasks.find((t) => t.id === task.leadTaskId);
          if (!leadTask) {
            return task;
          } else {
            return getLeadTask(leadTask);
          }
        };

        setTimeout(() => {
          this.calculateTotals();

          if (this.settings.grouping) {
            this.applyTotals([getLeadTask(group.value.projectTask)]);
          }
        });
      });

    group.controls['projectTask'].valueChanges
      .pipe(debounceTime(0), takeUntil(this.reloaded$))
      .subscribe(() => {
        this.updateTasks();
        this.calculateTotals();
        this.updateFormArray();
      });

    return group;
  }

  /**
   * Gets localized current grouping label.
   *
   * @returns Localized grouping label.
   * */
  public getCurrentGroupingLabel(): string {
    return this.translate.instant(
      `projects.projects.card.finance.grouping.${this.settings?.grouping}`,
    );
  }

  /**
   * Изменение группировки строк.
   * Sets row grouping.
   *
   * @param grouping Row grouping.
   * */
  public setGrouping(grouping: any) {
    this.settings.grouping = grouping;
    this.localStorageService.store(this.storageSettingsName, this.settings);

    this.updateFormArray();
  }

  /**
   * Calculates Task and general totals.
   * */
  private calculateTotals() {
    this.tasksAmounts = {};

    const calculateTasksTotal = (tasks: ProjectTask[]): number => {
      let totalAmount = 0;
      tasks.forEach((task) => {
        let amount =
          this.entries
            .filter((e) => e.projectTask.id === task.id)
            ?.reduce((total, entry) => total + entry.amount, 0) ?? 0;

        const children = this.tasks.filter((t) => t.leadTaskId === task.id);
        amount += calculateTasksTotal(children);
        totalAmount += amount;
        this.tasksAmounts[task.id] = amount;
      });

      return totalAmount;
    };

    // First level tasks.
    const topTasks = this.tasks.filter(
      (task) =>
        !task.leadTaskId ||
        !this.tasks.find((leadTask) => leadTask.id === task.leadTaskId),
    );

    this.totals = {};
    this.totals['account'] = uniq(this.entries.map((e) => e.account.id)).length;
    this.totals['expenseRule'] = uniq(
      this.entries.filter((e) => !!e.expenseRule).map((e) => e.expenseRule.id),
    ).length;
    this.totals['amount'] = calculateTasksTotal(topTasks);
    this.totals['projectTask'] = this.entries.length;
  }

  /**
   * Updates task structure by rows.
   * */
  private updateTasks() {
    this.tasks = [];

    // Get task structure.
    this.entries.forEach((entry) => {
      if (
        entry.projectTask &&
        !this.tasks.find((t) => t.id === entry.projectTask.id)
      ) {
        entry.projectTask.isExpanded = true;
        this.tasks.push(entry.projectTask);
      }
    });

    /**
     * Restores broken chains recursively.
     *
     * @param task Project Task.
     * */
    const restoreLinks = (task: ProjectTask) => {
      if (!task.leadTaskId) {
        return;
      }
      const leadTask = this.allTasks.find((t) => t.id === task.leadTaskId);

      // Do not add Main task if it has children.
      if (
        leadTask?.leadTaskId &&
        !this.tasks.find((t) => t.id === leadTask.id)
      ) {
        leadTask.isExpanded = true;
        this.tasks.push(leadTask);
        restoreLinks(leadTask);
      }
    };

    this.tasks.forEach((task) => {
      restoreLinks(task);
    });
  }

  /**
   * Updates Form array by data.
   * */
  private updateFormArray() {
    this.calculateTotals();

    this.autosave.disabled = true;
    const lines: ExpenseEstimateLine[] = [];

    if (this.settings.grouping === 'byTasks') {
      const minIndent = minBy(this.tasks, (t) => t.indent)?.indent ?? 0;

      const addLevel = (tasksInLevel: ProjectTask[]) => {
        tasksInLevel
          .sort(naturalSort('structNumber'))
          .forEach((projectTask) => {
            // Add Task row.
            lines.push({
              indent: projectTask.indent - minIndent,
              amount: this.tasksAmounts[projectTask.id],
              projectTask,
              id: projectTask.id,
              isTaskGroup: true,
              isExpanded: projectTask.isExpanded,
            });

            if (!projectTask.isExpanded) {
              return;
            }

            // Find all Labor cost rows.
            let entries = this.entries.filter(
              (entry) => entry.projectTask?.id === projectTask.id,
            );

            entries = orderBy(
              entries,
              ['added', 'date', 'created'],
              ['desc', 'asc', 'asc'],
            );

            entries.forEach((entry) => {
              lines.push({
                ...entry,
                isTaskGroup: false,
                isRuleEntry: !!entry.expenseRule,
                isRuleReadonly:
                  entry.expenseRule?.calculationMethod ===
                  RuleCalculationMethod.Percent,
              });
            });

            addLevel(
              this.tasks.filter((task) => task.leadTaskId === projectTask.id),
            );
          });
      };

      // Move all tasks without lead ones to the top level even if it has lead one (chain breaks).
      const tasks = this.tasks.filter(
        (task) =>
          !task.leadTaskId ||
          !this.tasks.find((leadTask) => leadTask.id === task.leadTaskId),
      );

      addLevel(tasks);
    } else {
      const entries = orderBy(
        this.entries,
        ['added', 'date', 'created'],
        ['desc', 'asc', 'asc'],
      );

      entries.forEach((entry) => {
        lines.push({
          ...entry,
          isTaskGroup: false,
          isRuleEntry: !!entry.expenseRule,
          isRuleReadonly:
            entry.expenseRule?.calculationMethod ===
            RuleCalculationMethod.Percent,
        });
      });
    }

    // Update grid structure form.
    for (let index = 0; index < lines.length; index++) {
      const line = lines[index];

      // If Task has group at index.
      if (line.id === this.formArray.at(index)?.value.id) {
        continue;
      }

      const groupIndex = findIndex(
        this.formArray.value,
        (r: any) => r.id === line.id,
        index + 1,
      );

      // Group is missing in rows.
      if (groupIndex === -1) {
        const newGroup = this.getLineGroup();
        if (line.isRuleReadonly) {
          newGroup.disable({ emitEvent: false });
        } else if (line.isRuleEntry) {
          newGroup.controls.account.disable({ emitEvent: false });
          newGroup.controls.projectTask.disable({ emitEvent: false });
        }

        this.formArray.insert(index, newGroup);
        continue;
      }

      // Group exists in further rows.
      const group = this.formArray.at(groupIndex);
      this.formArray.removeAt(groupIndex);
      this.formArray.insert(index, group);
    }

    // Remove extra groups.
    while (lines.length !== this.formArray.controls.length) {
      this.formArray.removeAt(this.formArray.controls.length - 1);
    }

    // Update form value.
    this.formArray.patchValue(lines, { emitEvent: false });

    // Trigger grid change detector.
    this.gridService.detectChanges();

    if (this.readonly) {
      this.formArray.disable({ emitEvent: false });
    }

    this.autosave.disabled = false;
  }

  /**
   * Applies Task totals without structure update.
   *
   * @param tasksToApply Task list.
   * */
  private applyTotals(tasksToApply: ProjectTask[]) {
    const applyTotals = (tasks: ProjectTask[]) => {
      tasks.forEach((task) => {
        const groupIndex = (this.formArray.value as any[]).findIndex(
          (l) => l.isTaskGroup && l.projectTask?.id === task.id,
        );

        if (groupIndex !== -1) {
          (this.formArray.at(groupIndex) as UntypedFormGroup).controls[
            'amount'
          ].setValue(this.tasksAmounts[task.id], { emitEvent: false });
        }

        const children = this.tasks.filter((t) => t.leadTaskId === task.id);
        applyTotals(children);
      });
    };

    applyTotals(tasksToApply);

    this.gridService.detectChanges();
  }

  /**
   * Creates Expense request entity by Expense estimate.
   * Navigates to created entity.
   *
   * @param group Expense estimate form group.
   * */
  private createRequest(group: UntypedFormGroup) {
    this.blockUI.start();
    const groupValue = group.getRawValue();
    const data = {
      date: DateTime.now().toISODate(),
      name: 'new',
      projectId: this.entityId,
      userId: this.app.session.user.id,

      lines: [
        {
          description: groupValue.description,
          amount: groupValue.amount,
          date: groupValue.date,
          accountId: groupValue.account?.id ?? null,
          projectTaskId: groupValue.projectTask?.id ?? null,
        },
      ],
    };

    this.data
      .collection('ExpenseRequests')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal('expenses.creation.messages.created');
          this.blockUI.stop();
          this.state.go(`expensesRequest`, {
            entityId: response.id,
            routeMode: RouteMode.continue,
          });
        },
        error: (error: Exception) => {
          this.message.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  /**
   * Clears Project/Project version Expense estimates.
   * Reloads Project card.
   * */
  private clear() {
    this.messageService
      .confirmLocal('projects.actions.clearDataConfirmation')
      .then(
        () => {
          this.blockUI.start();
          this.versionDataService
            .projectCollectionEntity(
              this.versionCardService.projectVersion,
              this.entityId,
            )
            .action('ClearExpenseEstimates')
            .execute()
            .subscribe({
              next: () => {
                this.blockUI.stop();
                this.projectCardService.reloadTab();
              },
              error: (error: Exception) => {
                this.blockUI.stop();
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  /**
   * Gets OData query to fetch Expense estimates.
   * */
  private getQuery() {
    const query: any = {
      select: ['id', 'date', 'amount', 'description', 'created'],
      expand: {
        account: {
          select: ['id', 'name'],
        },
        expenseRule: {
          select: ['id', 'name', 'calculationMethod'],
        },
        projectTask: {
          select: ['id', 'name', 'indent', 'leadTaskId', 'structNumber'],
        },
      },
      orderBy: ['date', 'created'],
    };

    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      this.versionCardService.projectVersion,
      this.entityId,
    );

    // don't show corporate tax in list.
    query.filter.push({
      accountId: {
        ne: {
          type: 'guid',
          value: FinancialAccount.corporateTaxId,
        },
      },
    });

    return query;
  }
}
