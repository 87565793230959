import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Invoice } from 'src/app/shared/models/entities/billing/invoice.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { GroupingField } from './shared/grouping-field.enum';
import { MessageService } from 'src/app/core/message.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { InvoiceValueCellComponent } from './shared/invoice-value-cell/invoice-value-cell.component';
import { InvoiceValueReadonlyCellComponent } from './shared/invoice-value-cell/invoice-value-readonly-cell.component';
import { InvoiceAmountCellComponent } from 'src/app/billing/invoices/card/shared/invoice-amount-cell/invoice-amount-cell.component';
import { UntypedFormGroup } from '@angular/forms';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { InvoiceDescriptionCellComponent } from './shared/invoice-description-cell/invoice-description-cell.component';
import { InvoiceDescriptionReadonlyCellComponent } from './shared/invoice-description-cell/invoice-description-readonly-cell.component';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { InvoiceSettings } from './shared/invoice.settings';
import { InvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-line-type.enum';
import { ResetInvoiceGrouping } from 'src/app/shared/models/entities/billing/reset-invoice-grouping.model';
import { AppConfigService } from 'src/app/core/app-config.service';
import { saveAs } from 'file-saver';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import {
  GridColumn,
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { Grid2Options } from 'src/app/shared-features/grid2/models/grid-options.model';

@Injectable()
export class InvoiceCardService {
  public collection = this.data.collection('Invoices');

  private invoiceQuery = {
    expand: {
      state: { select: ['id', 'name', 'code', 'style'] },
      invoiceTemplate: {
        select: ['id', 'name'],
        expand: { vatRate: { select: ['id', 'code', 'name', 'rate'] } },
      },
      project: {
        select: ['id', 'name', 'billingTypeId'],
        expand: {
          currency: { select: ['id', 'name', 'alpha3Code'] },
        },
      },
      organization: { select: ['id', 'name'] },
      user: { select: ['id', 'name'] },
      lines: {
        expand: {
          role: { select: ['id', 'name'] },
          project: { select: ['id', 'name'] },
          projectTask: { select: ['id', 'name'] },
          user: { select: ['id', 'name'] },
          account: { select: ['id', 'name'] },
          projectCostCenter: { select: ['id', 'name'] },
          projectTariff: { select: ['id', 'name'] },
        },
      },
    },
  };

  private _state = new BehaviorSubject<CardState>(CardState.Loading);

  private _invoice = new BehaviorSubject<Invoice>(null);

  private _resetGrouping = new Subject<ResetInvoiceGrouping>();

  private _customLineAdded = new Subject<UntypedFormGroup>();

  private _calculateTotals = new Subject<void>();

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  public state$ = this._state.asObservable();

  public invoice$ = this._invoice.asObservable().pipe(filter((p) => !!p));

  public resetGrouping$ = this._resetGrouping.asObservable();

  public customLineAdded$ = this._customLineAdded.asObservable();

  public calculateTotals$ = this._calculateTotals.asObservable();

  public allTimeFields: GroupingField[] = [
    GroupingField.projectTariff,
    GroupingField.projectTask,
    GroupingField.user,
    GroupingField.role,
    GroupingField.date,
    GroupingField.description,
    GroupingField.projectCostCenter,
  ];

  public allExpensesFields: GroupingField[] = [
    GroupingField.date,
    GroupingField.account,
    GroupingField.user,
    GroupingField.description,
    GroupingField.projectCostCenter,
  ];

  get entityId(): string {
    return this._entityId;
  }

  set entityId(value: string) {
    this._entityId = value;
  }

  private _entityId: string;

  private baseColumns: GridColumn[] = [
    {
      name: 'unit',
      header: 'billing.invoices.card.columns.unit.header',
      hint: 'billing.invoices.card.columns.unit.hint',
      type: GridColumnType.String,
      width: '120px',
    },
    <GridComponentColumn>{
      name: 'rate',
      header: 'billing.invoices.card.columns.rate.header',
      hint: 'billing.invoices.card.columns.rate.hint',
      type: GridColumnType.Component,
      max: 9999999999,
      min: -9999999999,
      component: InvoiceValueCellComponent,
      readonlyComponent: InvoiceValueReadonlyCellComponent,
      width: '130px',
    },
    <GridComponentColumn>{
      name: 'quantity',
      header: 'billing.invoices.card.columns.quantity.header',
      hint: 'billing.invoices.card.columns.quantity.hint',
      type: GridColumnType.Component,
      max: 9999999999,
      component: InvoiceValueCellComponent,
      readonlyComponent: InvoiceValueReadonlyCellComponent,
      width: '110px',
    },
    <GridComponentColumn>{
      name: 'amount',
      header: 'billing.invoices.card.columns.amount.header',
      hint: 'billing.invoices.card.columns.amount.hint',
      type: GridColumnType.Component,
      component: InvoiceAmountCellComponent,
      width: '135px',
    },
  ];

  constructor(
    private translate: TranslateService,
    private autosave: AutosaveService,
    private configService: LocalConfigService,
    private blockUI: BlockUIService,
    private httpClient: HttpClient,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
    private actionService: ActionPanelService,
    private navigation: NavigationService,
    private lifecycleService: LifecycleService,
  ) {
    lifecycleService.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reload();
    });
  }

  /**
   * Loads Invoice.
   * */
  public load = () => {
    this._state.next(CardState.Loading);

    this.actionService.actions.forEach((a) =>
      this.actionService.action(a.name).hide(),
    );

    this.collection
      .entity(this.entityId)
      .get<Invoice>(this.invoiceQuery)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (invoice) => {
          this.navigation.addRouteSegment({
            id: invoice.id,
            title: invoice.name,
          });

          this._invoice.next(invoice);
          this._state.next(CardState.Ready);

          // Backward compatibility. Invoice before June 2022 upgrade, keep Project grouping.
          if (
            !invoice.project &&
            !this.allTimeFields.includes(GroupingField.project)
          ) {
            this.allTimeFields.push(GroupingField.project);
            this.allExpensesFields.push(GroupingField.project);
          }
        },
        error: (error: Exception) => {
          this._state.next(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notification.error(error.message);
          }
        },
      });
  };

  /**
   * Reloads Card.
   * */
  public reload() {
    this.autosave.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  /**
   * Disposes service active resources.
   * */
  public dispose() {
    this.destroyed$.next();
  }

  /**
   * Gets Localized field label for grouping.
   *
   * @param field Field.
   * @returns Localized field label.
   * */
  public getFieldLabel(field: GroupingField): string {
    switch (field) {
      case GroupingField.user:
        return this.translate.instant(
          'billing.invoices.card.columns.user.header',
        );
      case GroupingField.project:
        return this.translate.instant(
          'billing.invoices.card.columns.project.header',
        );
      case GroupingField.projectTask:
        return this.translate.instant(
          'billing.invoices.card.columns.task.header',
        );
      case GroupingField.date:
        return this.translate.instant(
          'billing.invoices.card.columns.date.header',
        );
      case GroupingField.role:
        return this.translate.instant(
          'billing.invoices.card.columns.role.header',
        );
      case GroupingField.account:
        return this.translate.instant('shared2.props.financialAccount');
      case GroupingField.description:
        return this.translate.instant(
          'billing.invoices.card.columns.description.header',
        );
      case GroupingField.projectCostCenter:
        return this.translate.instant(
          'billing.invoices.card.columns.projectCostCenter.header',
        );
      case GroupingField.projectTariff:
        return this.translate.instant(
          'billing.invoices.card.columns.projectTariff.header',
        );
    }
  }

  /**
   * Builds Invoice lines grid view columns list by grouping fields string.
   *
   * @param grouping Grouping fields string.
   * @param gridOptions Grid options to update.
   * */
  public buildGridColumns(grouping: string, gridOptions: Grid2Options) {
    const fields = (grouping ? grouping.split(',') : []) as GroupingField[];
    const columns: Array<GridColumn> = [];

    if (fields.length === 0) {
      columns.push(this.getGridColumnDefinition(null));
    }

    fields.forEach((field) => {
      columns.push(this.getGridColumnDefinition(field));
    });

    gridOptions.view.columns = columns.concat(this.baseColumns.slice());
  }

  /**
   * Gets Invoice settings config.
   *
   * @returns Invoice settings config.
   * */
  public getInvoiceSettings(): InvoiceSettings {
    const settings = this.configService.getConfig(InvoiceSettings);

    const removeProject = (grouping: string) => {
      let items = grouping ? grouping.split(',') : [];
      items = items.filter((item) => item !== 'Project');
      return items.join(',');
    };

    settings.expenseLinesGrouping = removeProject(
      settings.expenseLinesGrouping,
    );

    settings.timeLinesGrouping = removeProject(settings.timeLinesGrouping);

    return settings;
  }

  /**
   * Updates Invoice settings period.
   *
   * @param period New period value.
   * */
  public updateStoredPeriod(period: DatePeriod) {
    const settings = this.getInvoiceSettings();
    settings.period = period;
    this.configService.setConfig(InvoiceSettings, settings);
  }

  /**
   * Updates Invoice settings.
   *
   * @param settings New settings value.
   * */
  public updateLocalSettings(settings: InvoiceSettings) {
    this.configService.setConfig(InvoiceSettings, settings);
  }

  /**
   * Resets Invoice Time/Expense line grouping. Removes all Time/Expense lines.
   * Saves entity changes.
   *
   * @param resetGrouping Reset grouping params.
   * @returns Promise.
   * */
  public resetGrouping(resetGrouping: ResetInvoiceGrouping): Promise<void> {
    const settings = this.getInvoiceSettings();
    switch (resetGrouping.type) {
      case InvoiceLineType.Time:
        settings.timeLinesGrouping = resetGrouping.grouping;
        break;
      case InvoiceLineType.Expense:
        settings.expenseLinesGrouping = resetGrouping.grouping;
        break;
    }
    this.updateLocalSettings(settings);

    return new Promise((resolve, reject) => {
      this._resetGrouping.next(resetGrouping);
      this.autosave.save().then(
        () => {
          this.load();
          resolve();
        },
        () => reject(),
      );
    });
  }

  /**
   * Deletes Invoice.
   * */
  public deleteInvoice() {
    this.message
      .confirmLocal('billing.invoices.card.messages.deleteConfirmation')
      .then(
        () => {
          this.collection
            .entity(this.entityId)
            .delete()
            .subscribe({
              next: () => {
                this.notification.successLocal('shared.messages.deleted');
                this.navigation.goToSelectedNavItem();
              },
              error: (error: Exception) => {
                this.message.error(error.message).then(this.load, this.load);
              },
            });
        },
        () => null,
      );
  }

  /**
   * Downloads Invoice print form.
   *
   * @param invoice Invoice.
   * */
  public downloadInvoice(invoice: Invoice) {
    this.autosave.save().then(() => {
      const url = `${AppConfigService.config.api.url}/InvoiceDownload/${this.entityId}`;

      this.blockUI.start();
      this.httpClient
        .get(url, { responseType: 'blob' })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (data) => {
            saveAs(data, `${invoice.name}.pdf`);
            this.blockUI.stop();
          },
          error: (error: Exception) => {
            this.message.error(error.message);
            this.blockUI.stop();
          },
        });
    });
  }

  /**
   * Thr Invoice Custom line added event handler.
   * Selects newly added Custom line.
   *
   * @param group New line form group.
   * */
  public onCustomLineAdded(group: UntypedFormGroup) {
    this._customLineAdded.next(group);
  }

  /**
   * Calculates Invoice total sum values.
   * */
  public calculateTotals() {
    this._calculateTotals.next();
  }

  /**
   * Gets Grid column field definition.
   *
   * @param field Field.
   * @returns Grid column field definition.
   * */
  private getGridColumnDefinition(field: GroupingField): GridColumn {
    let column: GridColumn;

    switch (field) {
      case GroupingField.project:
        column = {
          name: 'project',
          header: 'billing.invoices.card.columns.project.header',
          hint: 'billing.invoices.card.columns.project.hint',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.projectTask:
        column = {
          name: 'projectTask',
          header: 'billing.invoices.card.columns.task.header',
          hint: 'billing.invoices.card.columns.task.hint',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.projectCostCenter:
        column = {
          name: 'projectCostCenter',
          header: 'billing.invoices.card.columns.projectCostCenter.header',
          hint: 'billing.invoices.card.columns.projectCostCenter.hint',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.projectTariff:
        column = {
          name: 'projectTariff',
          header: 'billing.invoices.card.columns.projectTariff.header',
          hint: 'billing.invoices.card.columns.projectTariff.hint',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.role:
        column = {
          name: 'role',
          header: 'billing.invoices.card.columns.role.header',
          hint: 'billing.invoices.card.columns.role.hint',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.account:
        column = {
          name: 'account',
          header: 'shared2.props.financialAccount',
          hint: 'shared2.props.financialAccount',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.user:
        column = {
          name: 'user',
          header: 'billing.invoices.card.columns.user.header',
          hint: 'billing.invoices.card.columns.user.hint',
          type: GridColumnType.Entity,
          width: '250px',
        };
        break;
      case GroupingField.date:
        column = {
          name: 'date',
          header: 'billing.invoices.card.columns.date.header',
          hint: 'billing.invoices.card.columns.date.hint',
          type: GridColumnType.Date,
          width: '120px',
        };
        break;
      case GroupingField.description:
        column = <GridComponentColumn>{
          name: 'description',
          header: 'billing.invoices.card.columns.description.header',
          hint: 'billing.invoices.card.columns.description.hint',
          type: GridColumnType.Component,
          component: InvoiceDescriptionCellComponent,
          readonlyComponent: InvoiceDescriptionReadonlyCellComponent,
          width: '200px',
        };
        break;
      default:
        column = {
          name: 'empty',
          header: '',
          hint: '',
          type: GridColumnType.String,
          width: '250px',
        };
        break;
    }

    return column;
  }
}
