import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-description-value-cell',
  templateUrl: './invoice-description-cell.component.html',
  styleUrls: ['./invoice-description-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceDescriptionCellComponent
  implements GridTemplateCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  private originalField = 'originalDescription';

  private originalValueString = this.translate.instant(
    'billing.invoices.card.originalValue',
  );

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  public get hasInfo(): boolean {
    return (
      this.formGroup.controls[this.originalField].value !== this.control.value
    );
  }

  public get originalHint(): string {
    const value = this.formGroup.controls[this.originalField].value;
    return `${this.originalValueString}:\n${value}`;
  }

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public service: GridService,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
