<form [formGroup]="lineForm" class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'expenses.card.lineCard.header' | translate }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="d-flex">
      <div class="form-group date-form-group">
        <label>
          {{ 'expenses.card.lineCard.properties.date.label' | translate }}
        </label>
        <wp-date-box formControlName="date"></wp-date-box>
      </div>

      <div class="form-group ms-2 w-100">
        <label>
          {{ 'shared2.props.financialAccount' | translate }}
        </label>
        <wp-select-box
          [values]="financialAccountsService.expensesRequestAccounts$"
          placeholder="{{ 'shared2.props.financialAccount' | translate }}"
          formControlName="account"
        ></wp-select-box>
      </div>
    </div>

    <div class="d-flex flex-row">
      <div class="form-group currency-form-group me-2">
        <label>
          {{ 'expenses.card.lineCard.properties.amount.label' | translate }}
        </label>
        <wp-currency-box
          class="amount-field"
          formControlName="amount"
          type="currency"
          [allowNull]="false"
        ></wp-currency-box>
      </div>
    </div>

    <div class="d-flex flex-row">
      <div class="form-group number-form-group me-2">
        <label>
          {{ 'expenses.card.columns.exchangeRate.header' | translate }}
        </label>
        <wp-number-box
          class="xr-field"
          formControlName="exchangeRate"
          type="decimal"
          precision="13"
          [allowNull]="false"
        ></wp-number-box>
      </div>
      <div class="form-group currency-form-group me-2">
        <label>
          {{ 'expenses.card.columns.amountBC.header' | translate }}
        </label>
        <wp-currency-box
          class="amount-field"
          formControlName="amountBC"
          type="currency"
          [allowNull]="false"
        ></wp-currency-box>
      </div>
      <div class="form-group currency-form-group me-2">
        <label>
          {{ 'expenses.card.columns.amountPC.header' | translate }}
        </label>
        <wp-currency-box
          class="amount-field"
          formControlName="amountPC"
          type="currency"
          [allowNull]="false"
        ></wp-currency-box>
      </div>
      <div class="form-group currency-form-group me-2">
        <label>
          {{ 'expenses.card.columns.amountRC.header' | translate }}
        </label>
        <wp-currency-box
          class="amount-field"
          formControlName="amountRC"
          type="currency"
          [allowNull]="false"
        ></wp-currency-box>
      </div>
    </div>

    <div class="d-flex">
      <div class="form-group w-100">
        <label>
          {{ 'expenses.card.lineCard.properties.task.label' | translate }}
        </label>
        <tmt-task-box
          [projectId]="cardService.projectId"
          [userId]="cardService.userId"
          placeholder="{{ 'expenses.card.lineCard.properties.task.label' | translate }}"
          formControlName="projectTask"
        />
      </div>
    </div>

    <div class="form-check m-0 float-start mt-2">
      <input formControlName="reimburse" type="checkbox" class="form-check-input" id="reimburse" />
      <label class="form-check-label" for="reimburse">
        {{ 'expenses.card.lineCard.properties.reimburse.label' | translate }}
      </label>
    </div>

    <div class="form-check checkbox-billable m-0 float-start mt-2">
      <input formControlName="billable" type="checkbox" class="form-check-input" id="billable" />
      <label class="form-check-label" for="billable">
        {{ 'expenses.card.lineCard.properties.billable.label' | translate }}
      </label>
    </div>

    <div class="clearfix pb-3"></div>

    <div class="form-group">
      <label>
        {{ 'shared.props.description' | translate }}
      </label>
      <wp-multiline-text-box
        rows="2"
        placeholder="{{ 'shared.props.description' | translate }}"
        formControlName="description"
      ></wp-multiline-text-box>
    </div>

    <wp-custom-fields
      class="d-block"
      [formGroup]="lineForm"
      entityType="ExpenseRequestLine"
      [isVisibleHr]="false"
    ></wp-custom-fields>

    <div class="form-group">
      <label>
        {{ 'shared.props.attachment' | translate }}
      </label>

      <ngx-dropzone
        #drop
        class="drop-zone-container"
        [multiple]="false"
        [maxFileSize]="maxAttachmentSize"
        (change)="onFileAdded($event)"
        *ngIf="!line?.attachment && !readonly"
      >
        <ngx-dropzone-label class="drop-zone-label">
          <button type="button" class="btn btn-default">
            {{ 'shared.actions.select' | translate }}
          </button>
          <span>
            {{ 'shared.orDragFileHere' | translate }}
          </span>
        </ngx-dropzone-label>
      </ngx-dropzone>

      <p *ngIf="line.attachment" class="form-text">
        <button type="button" class="btn btn-link p-0" (click)="openAttachment()">
          {{ line.attachment.fileName }}
        </button>
        <span *ngIf="!readonly" class="position-relative">
          <button
            type="button"
            class="close remove-attachment"
            title="{{ 'shared.actions.removeAttachment' | translate }}"
            (click)="removeAttachment()"
          >
            &times;
          </button>
        </span>
      </p>

      <p *ngIf="readonly && !line?.attachment" class="pt-1 text-body-secondary">
        {{ 'shared.valueNotSelected' | translate }}
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="!readonly" type="button" class="btn btn-primary" (click)="ok()">
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
