import { CodedEntity } from '../coded-entity.model';
import { FinancialAccountType } from './financial-account-type.enum';

export class FinancialAccount extends CodedEntity {
  public static get revenueId(): string {
    return 'd0e23321-fd5d-4982-8a72-23747b035026';
  }

  public static get laborCostId(): string {
    return '15476461-f0e2-4b70-990a-b4a3ccb5c87f';
  }

  public static get timeOffCostId(): string {
    return '13e9a026-b80f-4a08-8fd4-6a269f698004';
  }

  public static readonly corporateTaxId =
    'eedaa83c-5559-4319-806f-30ca66d9f11b';

  public static readonly systemIds: string[] = [
    this.laborCostId,
    this.timeOffCostId,
    this.revenueId,
    this.corporateTaxId,
  ];

  /** Expense or revenue. */
  type: FinancialAccountType;

  typeId: string;

  description: string;

  isSystem: boolean;
}
