import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { SkillEstimationCellComponent } from './skill-estimation-cell/skill-estimation-cell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddingSkillsModalComponent } from './adding-skills-modal/adding-skills-modal.component';
import { MessageService } from 'src/app/core/message.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { of } from 'rxjs';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';

import { concatMap, debounceTime } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { EmployeeMainService } from 'src/app/employees/card/employee-main/employee-main.service';
import { EmployeeCardService } from 'src/app/employees/card/employee-card.service';
import { ResourceRolesService } from 'src/app/shared-features/resource-roles/resource-roles.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Constants } from 'src/app/shared/globals/constants';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { Grid2Options } from 'src/app/shared-features/grid2/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import {
  GridColumnType,
  GridComponentColumn,
  GridDateControlColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';

@Component({
  selector: 'tmt-employee-main',
  templateUrl: './employee-main.component.html',
  styleUrls: ['./employee-main.component.scss'],
  providers: [
    GridService,
    ResourceRolesService,
    SavingQueueService,
    EmployeeMainService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeMainComponent implements OnInit, AfterViewInit {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  public readonly = false;
  public isShowAdditionalRolesGrid = false;
  public isProfileEditable = false;
  public form = this.fb.group({
    department: null,
    supervisor: null,
    grade: null,
    level: null,
    location: null,
    position: ['', Validators.maxLength(Constants.formNameMaxLength)],
    phone: ['', Validators.maxLength(Constants.formNameMaxLength)],
    email: ['', [Validators.required, Validators.email]],
    code: ['', Validators.maxLength(Constants.formCodeMaxLength)],
    resourcePool: [null, Validators.required],
    legalEntity: [null, Validators.required],
    role: null,
    competence: null,
    isActive: false,
    firstWorkDay: null,
    lastWorkDay: null,
  });
  public skills = this.fb.array([]);
  public gridOptions: Grid2Options = {
    rowCommands: [
      {
        name: 'deleteSkill',
        label: 'employees.card.estimation.actions.deleteSkill',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.removeUserSkill(formGroup, index);
        },
      },
      {
        name: 'deleteEstimation',
        label: 'employees.card.estimation.actions.deleteEstimation',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) => {
          formGroup.controls.estimation.setValue(null);
        },
      },
    ],
    view: {
      name: 'schedules',
      columns: [
        {
          name: 'skill',
          header: 'employees.card.estimation.props.skill',
          hint: 'employees.card.estimation.props.skill',
          type: GridColumnType.String,
          width: '100%',
        } as GridDateControlColumn,
        {
          name: 'estimation',
          header: 'employees.card.estimation.props.estimation',
          hint: 'employees.card.estimation.props.estimation',
          type: GridColumnType.Component,
          component: SkillEstimationCellComponent,
          width: '150px',
        } as GridComponentColumn,
      ],
    },
  };
  public feature = Feature;

  private user: User;
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public userId: string,
    public resourceRolesService: ResourceRolesService,
    public employeeCardService: EmployeeCardService,
    public appService: AppService,
    public employeeMainService: EmployeeMainService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private customFieldService: CustomFieldService,
    private modal: NgbModal,
    private gridService: GridService,
    private actionPanelService: ActionPanelService,
    private cdr: ChangeDetectorRef,
    private blockUI: BlockUIService,
    private savingQueueService: SavingQueueService,
  ) {
    this.customFieldService.enrichFormGroup(this.form, 'User');
  }

  ngOnInit() {
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.saveProfile(),
      },
    ]);

    this.form.disable();

    this.employeeCardService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.readonly = !(user as any).skillsEditAllowed;
      });

    this.employeeMainService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.form.patchValue(user);
        this.isProfileEditable = user.editAllowed;
        this.user = user;
        this.skills.clear();
        this.isShowAdditionalRolesGrid = !!user.additionalUserRoles.length;

        this.subscribeToEstimates(user);

        if (this.readonly) {
          this.skills.disable();
        }
        this.form.disable();
        this.gridService.detectChanges();
      });

    this.employeeMainService.load();

    this.resourceRolesService.changes$
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.savingQueueService.addToQueue(
          this.userId,
          this.resourceRolesService.save(),
        );
      });

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  ngAfterViewInit(): void {
    FormHelper.cascadeDependency(
      this.form,
      this.cascadeControls,
      [
        [
          {
            controlName: 'role',
          },
          {
            controlName: 'competence',
            dependedProperty: 'roleId',
          },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );
    this.cdr.detectChanges();
  }

  public addSkills() {
    const modalRef = this.modal.open(AddingSkillsModalComponent, {
      size: 'lg',
    });
    const instance = modalRef.componentInstance as AddingSkillsModalComponent;
    instance.userId = this.employeeMainService.entityId;
    instance.currentSkills = (this.skills.value as any[]).map(
      (us) => us.skillId,
    );

    modalRef.result.then(
      () => this.employeeMainService.load(),
      () => null,
    );
  }

  /** Saves profile. */
  public saveProfile(): void {
    this.form.markAllAsTouched();
    const user = this.form.value as User;

    if (this.form.valid) {
      this.blockUI.start();

      const data: Record<string, any> = {
        locationId: user.location?.id ?? null,
        phone: user.phone,
        email: user.email,
        levelId: user.level?.id ?? null,
        gradeId: user.grade?.id ?? null,
        position: user.position,
        isActive: user.isActive,
        supervisorId: user.supervisor?.id ?? null,
        resourcePoolId: user.resourcePool?.id ?? null,
        code: user.code,
        departmentId: user.department?.id ?? null,
        firstWorkDay: user.firstWorkDay,
        lastWorkDay: user.lastWorkDay,
        legalEntityId: user.legalEntity?.id,
        roleId: user.role?.id ?? null,
        competenceId: user.competence?.id ?? null,
      };

      this.customFieldService.assignValues(data, user, 'User');

      this.dataService
        .collection('Users')
        .entity(this.userId)
        .patch(data)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.notificationService.successLocal(
              'components.employeeMainComponent.messages.profileSaved',
            );
            this.form.markAsPristine();
            this.blockUI.stop();
            this.employeeMainService.load();
            this.actionPanelService.action('save').isShown = false;
          },
          error: (error: Exception) => {
            this.blockUI.stop();
            this.messageService.errorDetailed(error);
          },
        });
    } else {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
    }
  }

  /** Enables form. */
  public makeProfileEditable(): void {
    this.form.enable();
    this.actionPanelService.action('save').isShown = true;
  }

  /** Disables profile editing. */
  public onProfileEditingCancel(): void {
    this.form.patchValue(this.user);
    this.form.disable();
    this.form.markAsPristine();
    this.actionPanelService.action('save').isShown = false;
  }

  private reload() {
    if (!this.form.dirty) {
      this.employeeMainService.load();
    } else {
      this.messageService
        .confirmLocal('shared2.messages.leavePageMessage')
        .then(
          () => {
            this.employeeMainService.load();
            this.form.markAsPristine();
            this.form.disable();
          },
          () => null,
        );
    }
  }

  private removeUserSkill(group: UntypedFormGroup, index: number) {
    this.messageService
      .confirmLocal(
        'employees.card.estimation.messages.confirmationForSkillDelete',
      )
      .then(
        () => {
          this.dataService
            .collection('UserSkills')
            .entity(group.value.id)
            .delete()
            .subscribe({
              next: () => {
                this.notificationService.successLocal(
                  'employees.card.estimation.messages.userSkillDeleted',
                );
                this.skills.removeAt(index);
                this.skills.markAsDirty();
              },
              error: (error: Exception) => {
                this.notificationService.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  private subscribeToEstimates(user: User) {
    user.skills.forEach((userSkill) => {
      const group = this.fb.group({
        skill: userSkill.skill.name,
        skillId: userSkill.skill.id,
        estimation: userSkill.level,
        id: userSkill.id,
      });

      group.controls.estimation.valueChanges
        .pipe(
          concatMap(() => {
            if (this.readonly) {
              return of(null);
            }

            return this.dataService
              .collection('UserSkills')
              .entity(group.controls.id.value)
              .patch({
                level: group.controls.estimation.value,
              });
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe({
          next: () => null,
          error: (error: Exception) => {
            this.notificationService.error(error.message);
          },
        });

      this.skills.push(group);
    });
  }
}
