import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  DatePipe,
  FormatWidth,
  getLocaleDateFormat,
  getLocaleTimeFormat,
} from '@angular/common';
import { DateTime } from 'luxon';
import { NgbDateTimeStruct } from 'src/app/shared/components/controls/date-box/date-box.model';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';

@Injectable()
export class WpParserFormatter implements NgbDateParserFormatter {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private datePipe: DatePipe,
    private dateBox: DateBoxComponent,
  ) {}

  /**
   *
   * @param value {string} значение, полученное из текстового поля у dateBox
   * @returns отформатированное значение даты
   */
  public parse(value: string): NgbDateStruct {
    /**
     * Удаляем лишние символы, такие как: ./-
     */
    const valueWithoutSymbols = this.replaceSymbols(value);
    if (!valueWithoutSymbols) {
      return null;
    }

    /**
     * Получаем формат даты для текущих региональных настроек
     */
    const dateFormat = getLocaleDateFormat(this.locale, FormatWidth.Short);
    const timeFormat = getLocaleTimeFormat(this.locale, FormatWidth.Short);

    const format = `${dateFormat} ${timeFormat}`;

    /**
     * Делаем "умный" парсинг полученного значения в дату
     * Если пользователь ввел данные в формате: 22 03 2003 или 22-03-2003, 22/03/2003
     * данные преобразуютсяв 22*03*2003, где * - разделитель
     * который принят в текущих региональный настройках для
     * разделения дат
     *
     * Если пользователь ввел только день, вместо месяца и года подставляются текущие
     * Если ввел день и месяц, то подставляется текущий год
     *
     * Если пользователь ввел некорректную дату, возвращается предыдущее значение
     */
    const autocompletedValue =
      this.autocompleteByDateFormat(valueWithoutSymbols);

    if (!autocompletedValue) {
      return null;
    }

    /**
     * Преобразование даты в текущий региональный формат
     */
    const date = DateTime.fromFormat(autocompletedValue, format);
    if (!date.isValid) {
      return null;
    }

    this.dateBox.time = this.dateBox.time.set({
      hour: date.hour,
      minute: date.minute,
    });

    return <NgbDateStruct>{
      day: date.day,
      month: date.month,
      year: date.year,
      hour: date.hour,
      minute: date.minute,
    };
  }

  /** Formats incoming data to a localized short date string; */
  public format(date: NgbDateTimeStruct): string {
    return !date
      ? ''
      : this.datePipe.transform(
          DateTime.local(
            date.year,
            date.month,
            date.day,
            this.dateBox.time?.hour || 0,
            this.dateBox.time?.minute || 0,
          ).toISO(),
          'short',
        );
  }

  /**
   * Функция заменяет символы: (.), (-), (/), (space) на указанный разделитель
   *
   * @param value{string} строка, в которой нужно произвести замену
   * @param replaceWith{string} разделитель
   * @returns{string} модифицированная строка
   */
  private replaceSymbols(value: string, replaceWith = '/'): string {
    const dateRegexp = /\.| |\/|-|:|, /gm;
    return value.replace(dateRegexp, replaceWith);
  }

  /**
   * @param value{string} строка для форматирования
   * @returns{string} отформатированная по региональному формату дата
   */
  private autocompleteByDateFormat(value: string): string | null {
    const newValue = value.split('/');
    const newFormat = ['d', 'm', 'y', 'H', 'M'];

    if (newValue.length === 1 && newValue[0] === '') {
      return null;
    }

    let monthIndex = null;
    let yearIndex = null;
    let dayIndex = null;
    let hourIndex = null;
    let minuteIndex = null;

    newFormat.forEach((formatValue, index) => {
      switch (formatValue) {
        case DateTimeFormat.month:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getMonth() + 1);
          }
          monthIndex = index;
          break;
        case DateTimeFormat.day:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getDate());
          }
          dayIndex = index;
          break;
        case DateTimeFormat.year:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getFullYear());
          }
          yearIndex = index;
          break;

        case DateTimeFormat.hour:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getHours());
          }
          hourIndex = index;
          break;

        case DateTimeFormat.minute:
          if (!newValue[index]) {
            newValue[index] = String(new Date().getMinutes());
          }
          minuteIndex = index;
          break;
      }
    });

    if (newValue[yearIndex].length < 4) {
      const prevCenturyStart = 70;
      const prevCenturyEnd = 999;
      const isPrevCentury =
        +newValue[yearIndex] >= prevCenturyStart &&
        +newValue[yearIndex] <= prevCenturyEnd;

      let yearPrefix = isPrevCentury ? '1' : '2';
      const yearCurrentLength = newValue[yearIndex].length;
      for (let i = 0; i < 4 - yearCurrentLength - 1; i++) {
        yearPrefix += isPrevCentury ? '9' : '0';
      }
      newValue[yearIndex] = yearPrefix + newValue[yearIndex];
    }

    const padWithZero = (index: number): void => {
      if (newValue[index].length === 1) {
        newValue[index] = '0' + newValue[index];
      }
    };

    padWithZero(dayIndex);
    padWithZero(monthIndex);
    padWithZero(hourIndex);
    padWithZero(minuteIndex);

    const isDayCorrect = +newValue[dayIndex] <= 31 && +newValue[dayIndex] >= 1;
    const isMonthCorrect =
      +newValue[monthIndex] <= 12 && +newValue[monthIndex] >= 1;
    const isYearCorrect =
      +newValue[yearIndex] >= 1900 && +newValue[yearIndex] <= 9999;
    const isHoursCorrect =
      newValue[hourIndex] ||
      (+newValue[hourIndex] >= 0 && +newValue[hourIndex] <= 23);
    const isMinutesCorrect =
      newValue[minuteIndex] ||
      (+newValue[minuteIndex] >= 0 && +newValue[minuteIndex] <= 59);
    if (
      !isDayCorrect ||
      !isMonthCorrect ||
      !isYearCorrect ||
      !isHoursCorrect ||
      !isMinutesCorrect
    ) {
      return null;
    }

    const [day, month, year, hours, minutes] = newValue;
    return `${day}.${month}.${year} ${hours}:${minutes}`;
  }
}

export enum DateTimeFormat {
  day = 'd',
  month = 'm',
  year = 'y',
  hour = 'H',
  minute = 'M',
}
