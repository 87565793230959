import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { WORKFLOW_LIST } from 'src/app/shared/lists/workflow.list';
import { WorkflowListComponent } from './list/workflow-list.component';
import { WorkflowCreationComponent } from './creation/workflow-creation.component';
import { WorkflowCardComponent } from './card/workflow-card.component';
import { WorkflowToolbarComponent } from './card/workflow-toolbar/workflow-toolbar.component';
import { TransitionConditionsComponent } from './card/workflow-function/workflow-transition-modal/transition-conditions/transition-conditions.components';
import { WorkflowActionsComponent } from './card/workflow-function/workflow-actions/workflow-actions.component';
import { WorkflowNextFunctionComponent } from './card/workflow-function/workflow-next-function/workflow-next-function.component';
import { WorkflowFunctionComponent } from './card/workflow-function/workflow-function.component';
import { WorkflowTransitionsComponent } from './card/workflow-function/workflow-transitions/workflow-transitions.component';
import { WorkflowActionModalComponent } from './card/workflow-function/workflow-action-modal/workflow-action-modal.component';
import { WorkflowTransitionModalComponent } from './card/workflow-function/workflow-transition-modal/workflow-transition-modal.component';
import { WorkflowCardService } from './card/workflow-card.service';
import { PerformersModule } from 'src/app/settings-app/shared/performers/performers.module';
import { NamingLocalizationModule } from 'src/app/settings-app/shared/naming-localization/naming-localization.module';
import { LifecyclesModule } from 'src/app/settings-app/lifecycle/lifecycles.module';
import { TransitionFormService } from 'src/app/settings-app/lifecycle/card/state-modal/transition-modal/transition-form/transition-form.service';
import { WorkflowConditionComponent } from './card/workflow-condition/workflow-condition.component';
import { WorkflowConditionModalComponent } from './card/workflow-condition/workflow-condition-modal/workflow-conditon-modal.component';
import { WorkflowConditionToolbarComponent } from './card/workflow-condition/workflow-condition-toolbar/workflow-condition-toolbar.component';
import { WorkflowNotificationComponent } from './card/workflow-notification/workflow-notification.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { Grid2Module } from "../../shared-features/grid2/grid2.module";

@NgModule({
  declarations: [
    WorkflowListComponent,
    WorkflowCreationComponent,
    WorkflowCardComponent,
    WorkflowToolbarComponent,
    TransitionConditionsComponent,
    WorkflowActionsComponent,
    WorkflowActionModalComponent,
    WorkflowFunctionComponent,
    WorkflowConditionComponent,
    WorkflowNextFunctionComponent,
    WorkflowTransitionsComponent,
    WorkflowTransitionModalComponent,
    WorkflowConditionModalComponent,
    WorkflowConditionToolbarComponent,
    WorkflowNotificationComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PerformersModule,
    EntityListComponent,
    NamingLocalizationModule,
    UIRouterModule.forChild({
        states: [
            {
                name: 'settings.workflows',
                url: '/workflows',
                component: WorkflowListComponent,
                params: {
                    navigation: 'settings.workflows',
                },
                resolve: [
                    { provide: VIEW_NAME, useValue: 'default' },
                    { provide: LIST, useValue: WORKFLOW_LIST },
                ],
            },
            {
                name: 'settings.workflow',
                redirectTo: 'settings.workflow.schema',
                params: {
                    navigation: 'settings.workflows',
                },
                url: '/workflows/{entityId:guid}',
                component: WorkflowCardComponent,
                resolve: [
                    {
                        token: 'entityId',
                        deps: [Transition],
                        resolveFn: StateHelper.resolveEntityId,
                    },
                ],
            },
        ],
    }),
    LifecyclesModule,
    Grid2Module
],
  providers: [WorkflowCardService, TransitionFormService],
})
export class WorkflowsModule {}
