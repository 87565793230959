import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { concatMap, debounceTime, switchMap, tap } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';
import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { WorkflowFunctionType } from 'src/app/settings-app/workflows/model/workflow.enum';
import { Exception } from 'src/app/shared/models/exception';
import { GridColumnType } from 'src/app/shared/models/inner/grid-column.interface';
import { WorkflowDeclaration } from 'src/app/settings-app/workflows/model/workflow-declaration.interface';
import { WorkflowCardService } from './workflow-card.service';
import { WorkflowToolbarComponent } from './workflow-toolbar/workflow-toolbar.component';
import {
  WorkflowFunctionCondition,
  WorkflowFunctionNotification,
  WorkflowFunctionTask,
  WorkflowSchema,
} from 'src/app/settings-app/workflows/model/workflow-function.model';
import { MermaidSchemaService } from 'src/app/shared-features/mermaid-schema/mermaid-schema.service';
import {
  MermaidSchemaState,
  MermaidSchemaTransition,
} from 'src/app/shared-features/mermaid-schema/models/state.model';
import { cloneDeep, pick } from 'lodash';
import { TransitionFormService } from 'src/app/settings-app/lifecycle/card/state-modal/transition-modal/transition-form/transition-form.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-workflow-card',
  templateUrl: './workflow-card.component.html',
  styleUrls: ['./workflow-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MermaidSchemaService, GridService],
})
export class WorkflowCardComponent implements OnInit {
  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;

  public form: UntypedFormGroup = this.fb.group({
    isActive: null,
    initialState: [null, Validators.required],
    actionNameLabels: null,
    cancelNameLabels: null,
    description: null,
    initiatorCanCancelInstance: false,
    initiators: this.fb.array([]),
    functions: this.fb.array([]),
  });

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    editing: 'none',
    toolbar: WorkflowToolbarComponent,
    commands: [
      {
        name: 'createFunction',
        handlerFn: (type: string) => {
          const newGroup = this.getFunctionFormGroup();
          if (type === 'Finish') {
            newGroup.controls.type.setValue(WorkflowFunctionType.finish);
          }
          this.workflowService.editWorkflowFunction(newGroup);
        },
      },
      {
        name: 'createCondition',
        handlerFn: (type: string) => {
          const newGroup = this.getFunctionFormGroup();
          if (type === 'Condition') {
            newGroup.controls.type.setValue(WorkflowFunctionType.condition);
          }
          this.workflowService.editWorkflowCondition(newGroup);
        },
      },
      {
        name: 'createNotification',
        handlerFn: (type: string) => {
          const newGroup = this.getFunctionFormGroup();
          if (type === 'Notification') {
            newGroup.controls.type.setValue(WorkflowFunctionType.notification);
          }
          this.workflowService.editWorkflowNotification(newGroup);
        },
      },
      {
        name: 'editFunction',
        allowedFn: () => !!this.gridService.selectedGroup,
        handlerFn: () => {
          const type = this.gridService.selectedGroup.value.type;
          const workflowFunction = this.gridService.selectedGroup;
          switch (type) {
            case WorkflowFunctionType.condition:
              this.workflowService.editWorkflowCondition(workflowFunction);
              break;
            case WorkflowFunctionType.notification:
              this.workflowService.editWorkflowNotification(workflowFunction);
              break;
            default:
              this.workflowService.editWorkflowFunction(workflowFunction);
          }
        },
      },
      {
        name: 'delete',
        allowedFn: () =>
          this.gridService.selectedGroup &&
          (this.gridService.selectedGroup?.value.type ===
            WorkflowFunctionType.task ||
            this.gridService.selectedGroup?.value.type ===
              WorkflowFunctionType.condition ||
            this.gridService.selectedGroup?.value.type ===
              WorkflowFunctionType.notification),
        handlerFn: () => {
          this.deleteWorkflowFunction();
        },
      },
      {
        name: 'moveUp',
        allowedFn: () =>
          // The start function is fixed in the first place
          (this.gridService.selectedGroup?.value.type ===
            WorkflowFunctionType.task ||
            this.gridService.selectedGroup?.value.type ===
              WorkflowFunctionType.condition ||
            this.gridService.selectedGroup?.value.type ===
              WorkflowFunctionType.notification) &&
          this.gridService.selectedGroup.value.number > 1,
        handlerFn: () => {
          const functions = this.workflowFunctions;
          const workflowFunction = this.gridService.selectedGroup.value;
          const workflowIndex = workflowFunction.number;

          if (workflowIndex >= 2) {
            const group = functions.at(workflowIndex);
            functions.removeAt(workflowIndex);
            functions.insert(workflowIndex - 1, group);

            /** Коррекция позиционирования функций в workflow сервиса */
            const serviceFunctions = this.workflowService.workflow.functions;
            [
              serviceFunctions[workflowIndex - 1],
              serviceFunctions[workflowIndex],
            ] = [
              serviceFunctions[workflowIndex],
              serviceFunctions[workflowIndex - 1],
            ];

            this.gridService.selectGroup(group as UntypedFormGroup);
            this.updateNumbers();
            this.form.markAsDirty();
          }
        },
      },
      {
        name: 'moveDown',
        allowedFn: () =>
          // The finish function is fixed last
          (this.gridService.selectedGroup?.value.type ===
            WorkflowFunctionType.task ||
            this.gridService.selectedGroup?.value.type ===
              WorkflowFunctionType.condition ||
            this.gridService.selectedGroup?.value.type ===
              WorkflowFunctionType.notification) &&
          this.gridService.selectedGroup.value.number <
            this.workflowFunctions.length - 2,
        handlerFn: () => {
          const functions = this.workflowFunctions;
          const workflowFunction = this.gridService.selectedGroup.value;
          const workflowIndex = workflowFunction.number;

          if (workflowIndex < functions.length - 2) {
            const group = functions.at(workflowIndex);
            functions.removeAt(workflowIndex);
            functions.insert(workflowIndex + 1, group);

            /** Коррекция позиционирования функций в workflow сервиса */
            const serviceFunctions = this.workflowService.workflow.functions;
            [
              serviceFunctions[workflowIndex],
              serviceFunctions[workflowIndex + 1],
            ] = [
              serviceFunctions[workflowIndex + 1],
              serviceFunctions[workflowIndex],
            ];

            this.gridService.selectGroup(group as UntypedFormGroup);
            this.updateNumbers();
            this.form.markAsDirty();
          }
        },
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: () => {
          const workflowFunction = this.gridService.selectedGroup;
          const type = this.gridService.selectedGroup.value.type;
          switch (type) {
            case WorkflowFunctionType.condition:
              this.workflowService.editWorkflowCondition(workflowFunction);
              break;
            case WorkflowFunctionType.notification:
              this.workflowService.editWorkflowNotification(workflowFunction);
              break;
            default:
              this.workflowService.editWorkflowFunction(workflowFunction);
          }
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: () => {
          this.deleteWorkflowFunction();
        },
        allowedFn: (row) =>
          row.value.type === WorkflowFunctionType.task ||
          row.value.type === WorkflowFunctionType.condition ||
          row.value.type === WorkflowFunctionType.notification,
      },
    ],
    view: {
      name: 'functions',
      columns: [
        {
          name: 'name',
          header: 'settings.workflows.card.columns.function.header',
          hint: 'settings.workflows.card.columns.function.header',
          type: GridColumnType.StringControl,
        },
        {
          name: 'typeView',
          header: 'settings.workflows.card.columns.type.header',
          hint: 'settings.workflows.card.columns.type.header',
          type: GridColumnType.StringControl,
          width: '112px',
        },
        {
          name: 'performersString',
          header: 'settings.workflows.card.columns.performers.header',
          hint: 'settings.workflows.card.columns.performers.header',
          type: GridColumnType.StringControl,
        },
        {
          name: 'actionsString',
          header: 'settings.workflows.card.columns.actions.header',
          hint: 'settings.workflows.card.columns.actions.header',
          type: GridColumnType.StringControl,
        },
        {
          name: 'transitionsString',
          header: 'settings.workflows.card.columns.transitions.header',
          hint: 'settings.workflows.card.columns.transitions.header',
          type: GridColumnType.StringControl,
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  private states: NamedEntity[];

  public get actionNameLabels(): UntypedFormControl {
    return this.form.controls['actionNameLabels'] as UntypedFormControl;
  }

  public get cancelNameLabels(): UntypedFormControl {
    return this.form.controls['cancelNameLabels'] as UntypedFormControl;
  }

  public get workflowFunctions(): UntypedFormArray {
    return this.form.controls['functions'] as UntypedFormArray;
  }

  public get initiators(): UntypedFormArray {
    return this.form.controls['initiators'] as UntypedFormArray;
  }

  constructor(
    @Inject('entityId') public entityId: string,
    public workflowService: WorkflowCardService,
    public transitionFormService: TransitionFormService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private message: MessageService,
    private actionService: ActionPanelService,
    private blockUI: BlockUIService,
    private mermaidService: MermaidSchemaService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.initWorkflowCard();

    this.workflowService.update$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.form.markAsDirty();
        this.prepareFunctionsView();
      });

    this.workflowService.changes$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    const formValueChangingDebounceTime = 300;
    this.form.valueChanges
      .pipe(
        debounceTime(formValueChangingDebounceTime),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((formValue) => {
        this.updateMermaidSchema(formValue);
      });

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves workflow. */
  public save = (): void => {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const warnings = [];

    const workflow = cloneDeep(this.form.value);

    workflow.initialStateId = this.form.value.initialState?.id;

    let requiredEndFunctionsError = true;
    let requiredNextFunctionIdError = false;

    workflow.initiators = workflow.initiators.map((performerLine) => {
      const performer: any = pick(performerLine.performer, [
        'id',
        'name',
        'type',
      ]);

      if (performer.type === 'user') {
        performer.userId = performer.id;
      }

      if (performer.type === 'role') {
        performer.role = performer.id;
      }

      if (performer.type === 'group') {
        performer.groupId = performer.id;
      }

      if (performer.type === 'permissionSet') {
        performer.permissionSetId = performer.id;
      }

      delete performer.type;
      delete performer.id;
      delete performer.name;

      return performer;
    });

    workflow.functions.forEach((func) => {
      if (func.type === WorkflowFunctionType.start && !func.nextFunctionId) {
        warnings.push(
          'settings.workflows.card.messages.requiredNextFunctionForStartError',
        );
      }

      /** Проверка на наличие функции финиш */
      if (func.type === WorkflowFunctionType.finish) {
        requiredEndFunctionsError = false;
      }

      /** Проверка на наличие nextFunctionId у переходов функций */
      func.transitions?.forEach((transition) => {
        if (!transition.nextFunctionId) {
          requiredNextFunctionIdError = true;
        }
      });

      func.actions?.forEach((action) => {
        if (action.actionForm) {
          if (action.actionForm.requestedProperties) {
            action.actionForm.requestedProperties.map((property) => {
              if (property.nameControl) {
                property.name = property.nameControl.id;
                delete property.id;
                delete property.nameControl;
              }
            });
          }
        }
      });
    });

    if (requiredEndFunctionsError) {
      warnings.push(
        'settings.workflows.card.messages.requiredFinishFunctionError',
      );
    }

    if (requiredNextFunctionIdError) {
      warnings.push(
        'settings.workflows.card.messages.requiredNextFunctionIdError',
      );
    }

    if (warnings.length) {
      this.workflowService.showValidationWarning(warnings);
      return;
    }

    this.isSaving = true;
    this.blockUI.start();
    this.workflowService
      .updateWorkflowSchema(workflow)
      .pipe(
        switchMap(() =>
          this.workflowService.updateWorkflowDeclaration(
            this.form.value.isActive,
            this.form.value.description,
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.blockUI.stop();
          this.form.markAsPristine();
          this.cdr.detectChanges();
          this.notification.successLocal(
            'settings.workflows.card.messages.workflowSaved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.blockUI.stop();
          this.cdr.detectChanges();
          this.notification.error(error.message);
        },
      });
  };

  /**
   * Gets lifecycle filter.
   *
   * @returns Filter by lifecycle to return the state.
   */
  public getLifecycleFilter() {
    const lifecycle = this.workflowService.workflowDeclaration?.lifecycle;
    return lifecycle
      ? {
          filter: [{ lifecycleId: { type: 'guid', value: lifecycle.id } }],
        }
      : null;
  }

  /**
   * Saves name from header.
   *
   * @param name Name.
   * @returns Observable
   */
  public saveName = (name: string) =>
    new Observable((subscriber) => {
      this.workflowService
        .saveName(name)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          subscriber.next();
          this.cdr.detectChanges();
        });
    });

  private getFunctionFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      name: '',
      iconClass: null,
      number: 0,
      actions: [],
      labelStrings: null,
      nextFunctionId: null,
      performers: [],
      transitions: [],
      type: WorkflowFunctionType.task,
      typeView: '',
      actionsString: '',
      performersString: '',
      transitionsString: '',
      moveToStateId: null,
      skipIfPerformerIsInitiator: false,
      skipCompletedTask: false,
      skipActionId: null,
      conditionsForSkipping: [],
      isElse: null,
      expression: null,
      conditions: [],
      recipients: [],
    });
  }

  private updateNumbers(): void {
    let index = 0;
    const functions = this.workflowFunctions;
    functions.controls.forEach((workflowFunction: UntypedFormGroup) => {
      workflowFunction.controls.number.setValue(index);
      index++;
    });

    this.gridService.detectChanges();
  }

  private prepareForm(): void {
    this.gridService.detectChanges();

    const workflow = this.workflowService.workflow;
    this.form.patchValue(workflow);

    this.form.controls.description.setValue(
      this.workflowService.workflowDeclaration.description,
      { emitEvent: false },
    );

    this.form.controls.isActive.setValue(
      this.workflowService.workflowDeclaration.isActive,
      { emitEvent: false },
    );

    this.form.controls.initialState.setValue(
      this.states.find((state) => state.id === workflow.initialStateId),
    );

    this.initiators.clear();
    const initiators = workflow?.initiators;
    let initiatorsString = '';

    if (initiators) {
      initiatorsString = initiators.map((i) => i.name).join(',');
    }

    initiators?.forEach((initiator) => {
      const initiatorForm = this.workflowService.getPerformerFormGroup();
      initiatorForm.controls.performer.patchValue(initiator);
      this.initiators.push(initiatorForm);
    });
  }

  private prepareFunctionsView(): void {
    const functions = this.workflowFunctions;
    functions.clear();
    // Update information in gridService, so that the table shows the changes that have not yet been saved
    this.gridService.detectChanges();

    const workflowFunctions = this.workflowFunctions;
    const workflow = this.workflowService.workflow;

    workflow.functions.forEach((workflowFunction, functionIndex) => {
      const workflowFunctionTask = workflowFunction as WorkflowFunctionTask;

      const actions = workflowFunctionTask?.actions;
      const transitions = workflowFunctionTask?.transitions;
      const performers =
        workflowFunctionTask?.performers ??
        (workflowFunction as WorkflowFunctionNotification)?.recipients;
      const conditions = (workflowFunction as WorkflowFunctionCondition)
        ?.conditions;
      const transitionsOrConditions = transitions ?? conditions;
      let actionsString = '';
      let transitionsString = '';
      let performersString = '';
      const typeView = this.translate.instant(
        `settings.workflows.card.function.functionTypes.${workflowFunction.type}`,
      );

      if (actions) {
        actionsString = actions.map((a) => a.name).join(',');
      }

      if (transitionsOrConditions) {
        transitionsString = transitionsOrConditions
          .map((t) => t.name)
          .join(', ');
      }

      if (performers) {
        performersString = performers.map((p) => p.name).join(', ');
      }

      const group = this.getFunctionFormGroup();
      group.patchValue({
        ...workflowFunction,
        number: functionIndex,
        actionsString,
        transitionsString,
        performersString,
        typeView,
      });
      workflowFunctions.push(group);
    });

    this.form.markAllAsTouched();
    this.gridService.detectChanges();
    this.cdr.detectChanges();
  }

  private loadSchema(): void {
    this.isLoading = true;
    //Снимаю выделение с таблицы, иначе после перезагрузки в кэше остается старая formGroup
    this.gridService.selectGroup(null);
    this.workflowService
      .getWorkflowSchema()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (schema: WorkflowSchema) => {
          if (schema !== null) {
            this.workflowService.setWorkflow(schema);
            this.prepareForm();
            this.prepareFunctionsView();
          } else {
            const newSchema = {
              initiators: [],
              initialStateId: null,
              initiatorCanCancelInstance: false,
              functions: [
                {
                  id: Guid.generate(),
                  type: WorkflowFunctionType.start,
                  name: WorkflowFunctionType.start,
                },
              ],
            };
            this.workflowService.setWorkflow(newSchema);
            this.prepareForm();
            this.prepareFunctionsView();
          }
          this.setNavigation();
          this.form.markAsPristine();
          this.isLoading = false;
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.cdr.detectChanges();
          this.notification.error(error.message);
        },
      });
  }

  private reload(): void {
    if (!this.form.dirty) {
      this.initWorkflowCard();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.initWorkflowCard(),
        () => null,
      );
    }
  }

  private deleteWorkflowFunction(): void {
    const functions = this.workflowFunctions;
    const workflowFunction = this.gridService.selectedGroup.value;

    if (workflowFunction.type === WorkflowFunctionType.start) {
      this.notification.warningLocal(
        'settings.workflows.card.messages.requiredFunctionError',
      );
      return;
    }

    const index = functions.value.findIndex(
      (func) => func.id === workflowFunction.id,
    );

    if (index >= 0) {
      functions.removeAt(index);
      this.updateNumbers();
      /**Удаляем в буфере сервиса */
      this.workflowService.workflow.functions.splice(index, 1);
    }

    /**Удаляем nextFunctionIds */
    functions.controls.forEach((functionGroup: UntypedFormGroup) => {
      if (functionGroup.controls.nextFunctionId.value === workflowFunction.id) {
        functionGroup.controls.nextFunctionId.setValue(null);
      }
      const transitions = functionGroup.controls.transitions?.value;
      transitions?.forEach((transition) => {
        if (transition.nextFunctionId === workflowFunction.id) {
          transition.nextFunctionId = null;
        }
      });
      functionGroup.controls.transitions.setValue(transitions);
    });

    this.form.markAsDirty();
  }

  private setNavigation(): void {
    this.navigationService.addRouteSegment({
      id: `${this.entityId}`,
      title: this.workflowService.workflowDeclaration.name,
    });
    this.cdr.detectChanges();
  }

  private initWorkflowCard(): void {
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.isLoading = true;
    this.workflowService
      .getWorkflowDeclaration(this.entityId)
      .pipe(
        concatMap((workflowDeclaration: WorkflowDeclaration) => {
          this.readonly = !workflowDeclaration.editAllowed;
          this.actionService.action('save').isShown = !this.readonly;
          this.transitionFormService.setAllowedTransitionFormProperties(
            workflowDeclaration.lifecycleId,
            workflowDeclaration.lifecycle.entityType,
          );
          return this.workflowService.getStates();
        }),
        tap((states) => {
          this.states = states;
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.loadSchema();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  private updateMermaidSchema(workflowSchema): void {
    const mermaidSchemaData: MermaidSchemaState[] = [];
    workflowSchema.functions.forEach((schemaFunction) => {
      const transitionsOrConditions =
        (schemaFunction as WorkflowFunctionTask)?.transitions ??
        (schemaFunction as WorkflowFunctionCondition)?.conditions;
      const mermaidSchemaTransitions: MermaidSchemaTransition[] = [];
      if (transitionsOrConditions) {
        transitionsOrConditions.forEach((stateTransition) => {
          if (stateTransition.nextFunctionId) {
            mermaidSchemaTransitions.push({
              name: stateTransition.name,
              nextStateId: stateTransition.nextFunctionId,
              stateName: '',
              stateCode: '',
            });
          }
        });
      }
      if (schemaFunction.nextFunctionId) {
        mermaidSchemaTransitions.push({
          name: schemaFunction.name,
          nextStateId: schemaFunction.nextFunctionId,
          stateName: '',
          stateCode: '',
        });
      }
      const mermaidSchemaState: MermaidSchemaState = {
        name: schemaFunction.name,
        id: schemaFunction.id,
        index: schemaFunction.number,
        code: schemaFunction.name.replaceAll(' ', '').trim().toLowerCase(),
        isInitial: schemaFunction.type === 'Start',
        isLast: schemaFunction.type === 'Finish',
        transitions: mermaidSchemaTransitions,
        isNotification: schemaFunction.type === 'Notification',
        isCondition: schemaFunction.type === 'Condition',
      };
      mermaidSchemaData.push(mermaidSchemaState);
    });
    this.mermaidService.updateSchema({
      hideHead: true,
      hideTail: true,
      states: mermaidSchemaData,
    });
  }
}
