import { TotalType } from 'src/app/shared/models/inner/total-type';
import { List, LoadingStrategy } from '../models/inner/list';
import {
  GridColumnType,
  GridCurrencyColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';

export const BILLING_SUMMARY_LIST: List = {
  version: 3,
  name: 'billingSummary',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'organization',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'project',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'hours',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'timeAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'timeAmountPC',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'expenseAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'expenseAmountPC',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'customAmountBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'customAmountPC',
          visibleByDefault: true,
          width: 1,
        },
      ],
      order: { column: 'organization', reverse: false },
    },
  ],
  columns: [
    {
      name: 'organization',
      type: GridColumnType.Entity,
      header: 'billing.summary.columns.organization.header',
      hint: 'billing.summary.columns.organization.hint',
    },
    {
      name: 'project',
      type: GridColumnType.Entity,
      header: 'billing.summary.columns.project',
      hint: 'billing.summary.columns.project',
    },
    {
      name: 'projectCurrency',
      type: GridColumnType.Entity,
      header: 'billing.summary.columns.projectCurrency',
      hint: 'billing.summary.columns.projectCurrency',
    },
    <GridCurrencyColumn>{
      name: 'timeAmountBC',
      type: GridColumnType.Currency,
      header: 'billing.summary.columns.timeAmountBC.header',
      hint: 'billing.summary.columns.timeAmountBC.hint',
      availableTotals: [TotalType.Sum],
    },
    <GridCurrencyColumn>{
      name: 'timeAmountPC',
      type: GridColumnType.Currency,
      header: 'billing.summary.columns.timeAmountPC.header',
      hint: 'billing.summary.columns.timeAmountPC.hint',
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'expenseAmountBC',
      header: 'billing.summary.columns.expenseAmountBC.header',
      hint: 'billing.summary.columns.expenseAmountBC.hint',
      type: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    <GridCurrencyColumn>{
      name: 'expenseAmountPC',
      header: 'billing.summary.columns.expenseAmountPC.header',
      hint: 'billing.summary.columns.expenseAmountPC.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'customAmountBC',
      header: 'billing.summary.columns.customAmountBC.header',
      hint: 'billing.summary.columns.customAmountBC.hint',
      type: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    <GridCurrencyColumn>{
      name: 'customAmountPC',
      header: 'billing.summary.columns.customAmountPC.header',
      hint: 'billing.summary.columns.customAmountPC.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'costBC',
      header: 'billing.summary.columns.costBC.header',
      hint: 'billing.summary.columns.costBC.hint',
      type: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    <GridCurrencyColumn>{
      name: 'costPC',
      header: 'billing.summary.columns.costPC.header',
      hint: 'billing.summary.columns.costPC.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    {
      name: 'hours',
      header: 'billing.summary.columns.hours.header',
      hint: 'billing.summary.columns.hours.hint',
      type: GridColumnType.Work,
      availableTotals: [TotalType.Sum],
    },
  ],
  dataColumns: [
    {
      column: 'organization',
      field: ['organization.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'project',
      field: ['project.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'timeAmountBC',
      field: 'timeAmountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'timeAmountPC',
      field: 'timeAmountPC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'expenseAmountBC',
      field: 'expenseAmountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'expenseAmountPC',
      field: 'expenseAmountPC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'customAmountBC',
      field: 'customAmountBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'customAmountPC',
      field: 'customAmountPC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'costBC',
      field: 'costBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'costPC',
      field: 'costPC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'hours',
      field: 'duration',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectCurrency',
      field: ['project.currency.alpha3Code', 'project.currency.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
  ],
};
