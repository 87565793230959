<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>

  <form [formGroup]="formInputParams">
    <div class="form-check">
      <input formControlName="readonly" type="checkbox" class="form-check-input" id="readonly" />
      <label class="form-check-label" for="readonly"> Readonly </label>
    </div>
  </form>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>DateBox</h2>
  <!-- TODO Change inline params into a single object for component  -->
  <wp-date-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="date"
  ></wp-date-box>
  <br />
  <br />
  <p>
    Form value:<br />
    <code>
      {{ form.get('date').value | json }}
    </code>
  </p>

  <p>Form control is valid: {{ form.get('date').valid }}</p>
</div>

<div [formGroup]="form" style="width: 400px">
  <h2>DateTimeBox</h2>
  <wp-date-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="dateTime"
    includeTime="true"
  ></wp-date-box>
  <br />
  <br />
  <p>
    Form value:<br />
    <code>
      {{ form.get('dateTime').value | json }}
    </code>
  </p>

  <p>Form control is valid: {{ form.get('dateTime').valid }}</p>
</div>

<div [formGroup]="form" style="width: 400px">
  <h2>DateBox (non-nullable)</h2>
  <wp-date-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="dateNonNullable"
    [allowNull]="false"
  ></wp-date-box>
  <br />
  <br />
  <p>
    Form value:<br />
    <code>
      {{ form.get('dateNonNullable').value | json }}
    </code>
  </p>

  <p>Form control is valid: {{ form.get('dateNonNullable').valid }}</p>
</div>

<div [formGroup]="form" style="width: 400px">
  <h2>DateBox (with non-working days)</h2>
  <wp-date-box
    [readonly]="formInputParams.controls['readonly'].value"
    formControlName="dateWithSchedule"
    [checkIsDisabled]="checkNonWorkingDay"
  ></wp-date-box>
  <br />
  <br />
  <p>
    Form value:<br />
    <code>
      {{ form.get('dateWithSchedule').value | json }}
    </code>
  </p>

  <p>Form control is valid: {{ form.get('dateWithSchedule').valid }}</p>
</div>

