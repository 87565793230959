import {
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subscription } from 'rxjs';
import { ProjectBillingList } from 'src/app/shared/lists/project-billing.list';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectBillingMode } from 'src/app/shared/models/enums/project-billing-mode.enum';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { ProjectCardService } from '../../core/project-card.service';
import { FinancialTaskCellService } from '../../shared/financial-task-cell/financial-task-cell.service';
import { ProjectBillingService } from './project-billing.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

export const resolveBillingView = (info: ProjectCardService) =>
  info.project.billingEstimationSettings.mode === ProjectBillingMode.automatic
    ? 'withMode'
    : 'default';

@Component({
  selector: 'wp-project-billing',
  templateUrl: './project-billing.component.html',
  providers: [
    SavingQueueService,
    ListService,
    GridService,
    ProjectBillingService,
    FinancialTaskCellService,
    { provide: LIST, useValue: ProjectBillingList },
    {
      provide: VIEW_NAME,
      useFactory: resolveBillingView,
      deps: [ProjectCardService],
    },
  ],
})
export class ProjectBillingComponent implements OnInit, OnDestroy {
  private projectSubscription: Subscription;
  private reloadListener: Subscription;

  private destroyRef = inject(DestroyRef);

  constructor(
    private projectService: ProjectCardService,
    public projectBillingService: ProjectBillingService,
    private savingQueueService: SavingQueueService,
  ) {}

  ngOnInit(): void {
    this.projectSubscription = this.projectService.project$.subscribe(
      (project: Project) => {
        this.projectBillingService.setReadonly(
          !project.billingEstimateEditAllowed,
        );
        this.projectBillingService.updateWorkProjectVersionFlag();
        if (
          project.billingEstimationSettings.mode ===
          ProjectBillingMode.automatic
        ) {
          this.savingQueueService.save$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(() => this.projectBillingService.load(true));
        }
      },
    );

    this.reloadListener = this.projectService.reloadTab$.subscribe(() =>
      this.projectBillingService.load(),
    );

    this.projectBillingService.init();
  }

  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
    this.reloadListener.unsubscribe();
  }
}
