import {
  Component,
  Input,
  ChangeDetectorRef,
  OnInit,
  ViewRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';

@Component({
  selector: 'wp-invoice-amount-cell',
  templateUrl: './invoice-amount-cell.component.html',
  styleUrls: ['./invoice-amount-cell.component.scss'],
})
export class InvoiceAmountCellComponent
  implements GridTemplateCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;
  @Input() currencyCode: string;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  get controlValue(): number {
    return this.control.value?.value ?? this.control.value;
  }

  get currencyCodeValue() {
    return this.control.value.currencyCode ?? this.currencyCode;
  }

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
