import {
  Component,
  Input,
  ChangeDetectorRef,
  ViewRef,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GridTemplateCell } from 'src/app/shared/components/features/grid/grid-template-cell.interface';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridColumn } from 'src/app/shared-features/grid2/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'wp-invoice-value-cell',
  templateUrl: './invoice-value-cell.component.html',
  styleUrls: ['./invoice-value-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceValueCellComponent
  implements GridTemplateCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  private originalValueString = this.translate.instant(
    'billing.invoices.card.originalValue',
  );

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  get min(): number {
    return this.column['min'];
  }

  get max(): number {
    return this.column['max'];
  }

  public get hasInfo(): boolean {
    return !_.isEqual(
      this.formGroup.controls[this.originalField].value,
      this.control.value,
    );
  }

  public get isCurrencyControl() {
    return this.column.name === 'rate';
  }

  public get originalHint(): string {
    const value = this.formGroup.controls[this.originalField].value;
    const label = this.getFormattedValue(value);
    return `${this.originalValueString}:\n${label}`;
  }

  private get originalField(): string {
    return this.isCurrencyControl ? 'originalRate' : 'originalQuantity';
  }

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public service: GridService,
    public ref: ChangeDetectorRef,
    private currencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
  ) {}

  ngOnInit(): void {
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public getType(): 'currency' | 'decimal' {
    return this.isCurrencyControl ? 'currency' : 'decimal';
  }

  private getFormattedValue(value: any): string {
    return this.isCurrencyControl
      ? this.currencyPipe.transform(value.value, value.currencyCode)
      : this.numberPipe.transform(value, '1.0-2');
  }
}
