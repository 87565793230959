<tmt-contact-interaction-form />

@if (
  contactInteractionService.interactions().length ||
  contactInteractionFilterService.getODataFilter()?.length
) {
  <div class="interactions__filters">
    <wp-filter />
    <div class="form-check">
      <input
        [formControl]="checkboxControl"
        type="checkbox"
        class="form-check-input"
        id="showOnlyPlanned"
      />
      <label class="form-check-label" for="showOnlyPlanned">
        {{ 'components.contactInteractionComponent.props.showOnlyPlanned' | translate }}
      </label>
    </div>
    <wp-sort />
  </div>
}

<div class="interactions__indicator">
  <wp-loading-indicator [loading]="contactInteractionService.isLoading()" />
</div>

<div class="interactions__feed">
  @for (interaction of contactInteractionService.interactions(); track interaction.id) {
    <tmt-contact-interaction-item [interaction]="interaction" [index]="$index" />
  }
</div>
