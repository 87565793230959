import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { ProjectRevenueToolbarComponent } from './project-revenue-toolbar/project-revenue-toolbar.component';
import { ProjectRevenueModalComponent } from './shared/project-revenue-modal/project-revenue-modal.component';

import { ProjectRevenueEstimatesList } from 'src/app/shared/lists/project-revenue-estimates.list';
import { ProjectCardSharedModule } from '../../shared/project-card-shared.module';
import { ProjectRevenueEstimatesComponent } from './project-revenue-estimates.component';
import { ProjectRbcSharedModule } from '../shared/project-rbc-shared.module';
import { Grid2Module } from 'src/app/shared-features/grid2/grid2.module';

@NgModule({
  declarations: [
    ProjectRevenueEstimatesComponent,
    ProjectRevenueToolbarComponent,
    ProjectRevenueModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    ProjectRbcSharedModule,
    Grid2Module,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.rbc.revenue',
          url: '/revenue',
          component: ProjectRevenueEstimatesComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: ProjectRevenueEstimatesList },
          ],
        },
      ],
    }),
  ],
})
export class ProjectRevenueEstimatesModule {}
